import React, {useEffect} from "react";
import TextBoxes from "./index";
import DefaultTextBoxProps from "../../types/DefaultTextBoxProps";
import {numberValidator} from "../../validators/textFeildValidators";

interface Props extends DefaultTextBoxProps {
    wordCount?: boolean
}

const MultilineTextBox = (props: Props) => {
    const [text, setText] = React.useState<string>('');
    useEffect(() => {
        //console.log('EmailInputBox', props.onerror);
        setText(props.onerror ? props.onerror : '');
    }, [props.onerror]);

    useEffect(() => {
        const element = document.getElementById(props.id) as HTMLInputElement;
        if(element){
            if(text.length > 0){
                element.style.borderColor = '#CD123D'
            }else{
                element.style.borderColor = '#969696'
            }
        }
    }, [text])

    return (
        <>
            <TextBoxes
                id={props.id}
                type={'text'}
                placeholder={props.placeholder}
                style={props.style}
                className={props.className}
                disabled={props.disabled}
                onChange={props.onChange}
                onClick={props.onClick}
                onLostFocus={props.onLostFocus}
                onKeydown={props.onKeydown}
                value={props.value}
                fluid={props.fluid}
                wordCount={props.wordCount}
                multiLine={true}
            />
            <span className='email-validation'>{text}</span>
        </>
    )
}

export default MultilineTextBox