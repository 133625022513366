import React from 'react'

interface maxCharsProps{
    charCount:number
}

const MaxChars = (props:maxCharsProps)=>{
    return(
        <p className='exp-instructions'>{`(maximum of ${props.charCount} characters)`}</p>
    );
}

export default MaxChars