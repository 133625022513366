import React, {useEffect, useState} from "react";
import {Container, Grid} from "semantic-ui-react";
import DefaultCheckBox from "../../components/CheckBox/DefaultCheckBox";
import NameWithCheckBox from "../NameWithCheckBox/NameWithCheckBox";
import parse from 'html-react-parser'
import MultilineTextBox from "../../components/TextBoxes/MultilineTextBox";

interface InfoToggleProps {
    type: string,
    msg: string,
    update:string,
    update2:string,
    msg2: string,
    id: string,
    id2: string,
    value?: any,
    value2?: any,
    onChange?: any
    onChange2?: any
}

const InfoToggle = (props: InfoToggleProps) => {
    const adminColor = "#FFE852";
    const assesorColor = "#91A2B4";
    const [bgColor, setBgColor] = useState('AdminInfoToggle')
    const [msg1, setMsg1] = useState('')
    const [msg2, setMsg2] = useState('')
    useEffect(()=>{
        //eslint-disable-next-line
        let tempMsg = props.msg
        //eslint-disable-next-line
        let tempMsg2 = props.msg2
       /* if(tempMsg2.includes('\r\n')){
            tempMsg2 = tempMsg2.replace('\r\n','<br/>')
        }
        if(tempMsg.includes('\r\n')){
            tempMsg = tempMsg.replace('\r\n','<br/>')
        }*/
        setMsg1(tempMsg)
        setMsg2(tempMsg2)
    },[props])
    return (
        <React.Fragment>
            <Container fluid style={{backgroundColor: props.type == 'AdminInfoToggle' ? adminColor : assesorColor}}>
                {props.type == 'AdminInfoToggle' ? (
                    <Grid style={{padding: '30px'}}>
                        <Grid.Row columns={2}>
                            <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                <h3 style={{width: '90%'}}>Message from the Administrator</h3>
                                <br/>

                                <p>Please review the comments made from the Administrator regarding your registration. More
                                    information may be required from you to meet the eligibility criteria for your selected
                                    level of accreditation.</p>
                                <p style={{width: '90%', color: '#CD123D'}}><i>Please tick to confirm that you have provided the
                                    requested information required for your auditor profile assessment.</i></p>
                            </Grid.Column>
                            <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                    <MultilineTextBox
                                        id={props.id+'1'}
                                        disabled={true}
                                        value={msg1}
                                        fluid={true}
                                        wordCount={false}
                                        style={{
                                            width: '100%',
                                            /*lineHeight: '3',*/
                                            backgroundColor: '#e5e5e5',
                                            paddingLeft: '20px',
                                            paddingRight: '20px',
                                            paddingTop: '20px',
                                            height: '100px',
                                            borderRadius:'0px'
                                        }}
                                    />
                                {props.msg.length > 0 && (props.msg != "Please note the assessor has received information and is working on the assessment." && props.msg != "Please note the assessor has completed the assessment.") ? (<NameWithCheckBox id={props.id} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                  label={'I confirm that I have updated my profile with the requested information'}
                                                  value={props.value} onChange={props.onChange} />):null}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>) :
                    (
                        <Grid style={{padding: '20px'}}>
                            <Grid.Row>
                                <Grid.Column width={8} mobile={16} tablet={16} widescreen={8} largeScreen={8} computer={8}>
                                    <h3 style={{width: '90%'}}>Message from the Assessor</h3>
                                    <br/>

                                    <p>Please review the comments made from the Assessor regarding your
                                        registration. More information may be required from you to meet the eligibility
                                        criteria for your selected level of accreditation.</p>
                                    <p style={{width: '90%', color: '#CD123D'}}><i>Please tick to confirm that you have
                                        provided the requested information required for your auditor profile
                                        assessment.</i></p>
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={2}>
                                <Grid.Column width={8} widescreen={8} mobile={16} tablet={16} computer={8}>
                                    <><h3 style={{width: '90%'}}>Assessor 1 Requests</h3>
                                    <p  style={{
                                        width: '100%',
                                        backgroundColor:'#ffffff',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        minHeight: '40px',
                                        marginBottom:'-10px',
                                    }}>Last Updated : {props.update} </p>
                                        <MultilineTextBox
                                            id={props.id+'1'}
                                            disabled={true}
                                            fluid={true}
                                            value={msg1}
                                            wordCount={false}
                                            style={{
                                                width: '100%',
                                                /*lineHeight: '3',*/
                                                backgroundColor: '#e5e5e5',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                paddingTop: '20px',
                                                height: '100px',
                                                borderRadius:'0px'
                                            }}
                                        />
                                    {props.msg.length > 0 && (props.msg != "Please note the assessor has received information and is working on the assessment." && props.msg != "Please note the assessor has completed the assessment.") ? (<NameWithCheckBox id={props.id} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                      label={'I confirm that I have updated my profile with the requested information'}
                                                      value={props.value} onChange={props.onChange}/>) : null}</>
                                </Grid.Column>
                                <Grid.Column width={8} widescreen={8} mobile={16} tablet={16} computer={8}>
                                    <><h3 style={{width: '90%'}}>Assessor 2 Requests</h3>
                                    <p style={{
                                        width: '100%',
                                        backgroundColor:'#ffffff',
                                        paddingTop: '8px',
                                        paddingBottom: '8px',
                                        paddingLeft: '5px',
                                        paddingRight: '5px',
                                        minHeight: '40px',
                                        marginBottom:'-10px'
                                    }}>Last Updated : {props.update2} </p>
                                        <MultilineTextBox
                                            id={props.id+'2'}
                                            disabled={true}
                                            fluid={true}
                                            value={msg2}
                                            wordCount={false}
                                            style={{
                                                width: '100%',
                                                /*lineHeight: '3',*/
                                                backgroundColor: '#e5e5e5',
                                                paddingLeft: '10px',
                                                paddingRight: '10px',
                                                paddingTop: '20px',
                                                height: '100px',
                                                borderRadius:'0px'
                                            }}
                                        />
                                    {props.msg2.length > 0 && (props.msg2 != "Please note the assessor has received information and is working on the assessment." && props.msg2 != "Please note the assessor has completed the assessment.") ? (<NameWithCheckBox id={props.id2+ props.msg2} style={{width: '90%', marginTop:'10px'}} type={'checkbox'}
                                                      label={'I confirm that I have updated my profile with the requested information'}
                                                      value={props.value2} onChange={props.onChange2}/>) : null }</>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    )}
            </Container>
        </React.Fragment>
    );
}

export default InfoToggle;