import {Grid} from "semantic-ui-react";
import React, {useEffect} from "react";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import Position from "../../../framework/types/Position";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import Dropdowns from "../../../framework/components/DropDowns";
import MultilineTextBox from "../../../framework/components/TextBoxes/MultilineTextBox";

const AccreditationAssessment = () => {
    const [assessor, setAssessors] = React.useState<DropDownOptions[]>([]);
    return(
        <Grid>
            <Grid.Row></Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Grid>
                        <Grid.Row columns={3} >
                            <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                                <Dropdowns   selection={true}
                                                fluid={true}
                                                    id={'ddAssessor'}
                                                    options={assessor}
                                                    placeholder={'Select Assessor'}
                                />
                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>
                                <div className={'button-flex'}>
                                    <OutLineButton id={'btnAssignAll'} style={{width: '150px'}} onClick={() => {
                                        window.location.href = '/welcome'
                                    }} color='blue' className={"background-transaparent"} text={'Assign All'} />
                                </div>
                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>
                                <div className={'button-flex'}>
                                    <OutLineButton id={'btnAssign'} style={{width: '150px'}} onClick={() => {
                                        window.location.href = '/welcome'
                                    }} color='blue' className={"background-transaparent"} text={'Assign'} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
               <Grid.Column>
                   No assessors assigned
               </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <h2 style={{display: 'inline-flex'}}>Message Assessors</h2>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Dropdowns   selection={true}
                                 fluid={true}
                                 id={'ddMsgAssessor'}
                                 options={assessor}
                                 placeholder={'Select All Assign Assessors'}
                    />
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <MultilineTextBox
                        wordCount={true}
                         fluid={true}
                        className={'other_exp'}
                         id={'txtMessageToAssessors'}
                         placeholder={''}
                    />
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Grid>
                        <Grid.Row columns={3} >
                            <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>

                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>

                            </Grid.Column>
                            <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>
                                <div className={'button-flex'}>
                                    <OutLineButton id={'btnSend'} style={{width: '150px'}} onClick={() => {
                                        window.location.href = '/welcome'
                                    }} color='blue' className={"background-transaparent"} text={'Send'} />
                                </div>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Grid.Column>
                <Grid.Column></Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <h2 style={{display: 'inline-flex'}}>Assessment History</h2>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    No Admin Communication
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )

}

export default AccreditationAssessment