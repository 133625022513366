import {
    AuditorProfile,
    AuditorRegionValueProps,
    BusinessDetailsValueProps,
    PersonalDetailsValueProps, QualificationValueProps,
    RoadSafetyCoursesProps
} from "../auditor/profile/DataModal";
import MsgFromAdmin from "../../models/apiResponce/Auditor/MsgFromAdmin";
import ApiCallAxio from "../../framework/common/apiCallAxio";
import APICallMethods from "../../framework/types/APICallMethods";
import {AuditorPersonalDetailsRes} from "../../models/apiResponce/Auditor/AuditorPersonalDetailsRes";
import {AuditorBusinessDetailsRes} from "../../models/apiResponce/Auditor/AuditorBusinessDetailsRes";
import {SelectedRegions} from "../../models/apiResponce/Auditor/SelectedRegions";
import {AuditorCourseRess} from "../../models/apiResponce/Auditor/AuditorCourseRess";
import {AuditorQualificationRes} from "../../models/apiResponce/Auditor/AuditorQualificationRes";
import {AuditorRes} from "../../models/apiResponce/Auditor/AuditorRes";
import Audits from "../../models/apiResponce/Audits/Audits";
import {ExperianceRes, ExperticeRes} from "../../models/apiResponce/Auditor/QualificationRes";
import CoursesRes from "../../models/apiResponce/Courses/CoursesRes";
import moment from "moment";
import {fetchAuditorAudit} from "../auditor/profile/ProfileController";
import UserTitles from "../../models/apiResponce/Common/userTitles";
import States from "../../models/apiResponce/Common/States";
import {SendMessageState} from "../../framework/types/SendMessageState";
import {SearchRes} from "../../models/apiResponce/Auditor/SearchRes";
import {SearchReq} from "../../models/apiRequest/Auditor/SearchReq";
import {AuditorAuditRess} from "../../models/apiResponce/Auditor/AuditorAuditRess";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const fetchAuditorSearch = async (AuditorId: number,pageNumber:number) => {
    let objAuditorPersonalDetails: PersonalDetailsValueProps = {} as PersonalDetailsValueProps;
    let objAuditorBusinessDetails: BusinessDetailsValueProps = {} as BusinessDetailsValueProps;
    const objAuditorRoadSafetyAudit: object[] = [];
    const objAuditorRoadSafetyCourses: RoadSafetyCoursesProps[] = [];
    let objAuditorRegion: AuditorRegionValueProps = {} as AuditorRegionValueProps;
    let objQualification: QualificationValueProps = {} as QualificationValueProps;
    const AD: MsgFromAdmin = {} as MsgFromAdmin;
    const BD: MsgFromAdmin = {} as MsgFromAdmin;
    const RSA: MsgFromAdmin = {} as MsgFromAdmin;
    const RSC: MsgFromAdmin = {} as MsgFromAdmin;
    const AVA: MsgFromAdmin = {} as MsgFromAdmin;
    const QE: MsgFromAdmin = {} as MsgFromAdmin;

    //const url = `${baseUrl}/${sublink}/${version}/Auditor/${AuditorId}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorProfile?AuditorId=${AuditorId}`; //complete url

    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const auditList = await fetchAuditorAuditSearch(AuditorId, pageNumber);
    //eslint-disable-next-line
    //debugger;
    const objAuditorPre: AuditorPersonalDetailsRes = result.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes; //store the result in auditorPersonalDetailsRes object
    const objAuditorBusinessPre: AuditorBusinessDetailsRes = result.auditorProfileForAuditorRoleRes.auditorBusinessDetailsRes; //store the result in auditorBusinessDetailsRes object
    const objRegionPre: SelectedRegions[] = result.auditorProfileForAuditorRoleRes.auditorRegionsAvailableRes.selectedRegions;
    const objCoursesPre: AuditorCourseRess[] = result.auditorProfileForAuditorRoleRes.auditorCourseRess;
    const objQulificationPre: AuditorQualificationRes = result.auditorProfileForAuditorRoleRes.auditorQualificationRes;
    const objAuditor: AuditorRes = {
        auditorId: objAuditorPre.auditorId,
        titleId: objAuditorPre.titleId,
        firstName: objAuditorPre.firstName,
        middleName: objAuditorPre.middleName,
        lastName: objAuditorPre.lastName,
        email: objAuditorPre.email,
        newAuditorId: objAuditorPre.newAuditorId,
        auditorOldId: objAuditorPre.auditorId,
        applyingLevel: objAuditorPre.applyingLevel,
        b12MonthsMailReminder: false,
        bOverwriteExpiryDate: false,
        createdDate: "",
        dt14DaysMailReminder: "1900-01-01T00:00:00",
        dt3MonthsMailReminder: "1900-01-01T00:00:00",
        editable: objAuditorPre.isRegistrationInProgress == true || objAuditorPre.auditorsStateRes.auditorStatusId == 3 ? true : false,
        fiveYearsExp: false,
        gracePeriod: "",
        gracePeriodYn: "",
        inactiveDate: "1900-01-01T00:00:00",
        lastUpdated: "",
        oldUsername: "",
        orgAddress: objAuditorBusinessPre.orgAddress,
        orgBranch: objAuditorBusinessPre.orgBranch,
        orgCountryId: objAuditorBusinessPre.orgCountryId,
        orgName: objAuditorBusinessPre.orgName,
        orgOther: objAuditorBusinessPre.orgOther,
        orgPosition: objAuditorBusinessPre.orgPosition,
        orgPostcode: objAuditorBusinessPre.orgPostcode,
        orgStateId: objAuditorBusinessPre.orgStateId,
        orgSuburb: objAuditorBusinessPre.orgSuburb,
        previousAuditorLevel: objAuditorPre.auditorsStateRes != null ? objAuditorPre.auditorsStateRes.auditorLevel : 0,
        sevenYearsExp: false,
        uniqueGuid: "",
        isRegistration:objAuditorPre.isRegistrationInProgress
    }
    //eslint-disable-next-line
    let objRegion = {
        greateSyndey: false,
        north: false,
        south: false,
        west: false,
        act: false,
        vic: false,
        qld: false,
        sa: false,
        wa: false,
        tas: false,
        nt: false,
        overseas: false
    };
    objRegionPre.forEach((r: SelectedRegions) => {
        switch (r.regionId) {
            case 1:
                objRegion.greateSyndey = true;
                break;
            case 2:
                objRegion.north = true;
                break;
            case 3:
                objRegion.south = true;
                break;
            case 4:
                objRegion.west = true;
                break;
            case 5:
                objRegion.vic = true;
                break;
            case 6:
                objRegion.wa = true;
                break;
            case 7:
                objRegion.act = true;
                break;
            case 8:
                objRegion.qld = true;
                break;
            case 9:
                objRegion.sa = true;
                break;
            case 10:
                objRegion.tas = true;
                break;
            case 11:
                objRegion.nt = true;
                break;
            case 12:
                objRegion.overseas = true;
                break;
        }
    })
    const objContactDetails = {
        mobile: objAuditorBusinessPre.mobile,
        homePhone: objAuditorBusinessPre.homePhone,
        skype: objAuditorBusinessPre.skype,
        workEmail: objAuditorBusinessPre.workEmail,
        personalEmail: objAuditorBusinessPre.personalEmail,
        workPhone: objAuditorBusinessPre.workPhone,
        preferContactMethod: objAuditorBusinessPre.preferContactMethod
    };


    const objCourses: object[] = [];
    objCoursesPre.forEach((x) => {
        const course = {
            id: x.mappingAuditorCourseId,
            auditorId: x.auditorId,
            courseProvider: x.courseProviderName,
            courseName: x.courseName,
            courseDescription: "",
            courseLocation: x.location,
            dateStart: x.courseDate,
            dateComplete: x.courseDate,
            elapsedDate: x.courseElapseDate,
            statusId: 1,
            isVerified: x.isVerified,
            createdDate: x.courseDate,
            courseProviderEmail: x.courseProviderEmail,
            courseCertNum: x.certificateNumber,
            courseCertMailCnt: null,
            courseCertMailDate: null,
            courseId: x.courseId,
            courseTentativeClassification: x.courseTentativeClassification,
            adminEmailAskedNewCourse: null,
            dateByAdminAskedNewCourse: null,
            dateAssesssedBySuperadmin: null,
            resultAssesssedBySuperadmin: null
        }
        objCourses.push(course)
    })
    //eslint-disable-next-line
    const ads: Audits[] = [];
    auditList.forEach((adr) => {
        ads.push({
            auditId: adr.auditId,
            auditorId: AuditorId,
            auditStatusId: adr.auditStatusId,
            auditFinalSignoffDate: adr.auditFinalSignoffDate,
            auditTitle: adr.auditTitle,
            auditLocationDescription: adr.auditLocationDescription,
            auditLocationStateId: adr.countryStateId,
            auditClientCompany: "",
            auditContactPerson: "",
            auditContactPhone: "",
            auditContactMobile: "",
            auditContactEmail: "",
            stageId: adr.stageId,
            typeId: adr.typeId,
            elapseDate: adr.auditFinalSignoffDate,
            addedDate: adr.auditFinalSignoffDate,
            modifyDate: adr.auditFinalSignoffDate,
            isTeamLeader: adr.isTeamLeader,
            teamLeaderName: adr.teamLeaderName,
            isPubliclyVisible: adr.isTfNswProject,
            projectDesc: adr.auditTitle,
            auditPurposeScope: "",
            emailSentCounter: '',
            emailSentDate: '',
            isTfNswProject: adr.isTfNswProject,
            countryStateId: adr.countryStateId,
            countryState: null,
            auditLogs: "",
            auditorAudits: "",
            reports: "",
            isVerify: adr.isConfirmedByAdmin,
            linked: adr.isConfirmedByAdmin,
            auditStatusName:adr.auditStatusName,
            auditDisplayName: adr.auditDisplayName,
            auditPhaseId: adr.auditPhaseId,
            auditPhaseName: adr.auditPhaseName,
            auditStageName: adr.auditStageName,
            auditStatusIspublic: adr.auditStatusIspublic

        })
    })


    const objAudits: Audits[] = ads != null ? ads : []//result.audits;

    //eslint-disable-next-line
    let objExpert: ExperticeRes = {
        constructionProject: false,
        crashInvestigation: false,
        designConstructionProjects: false,
        developmentPlanning: false,
        intersectionProjects: false,
        localAreaTrafficManagementProjects: false,
        reconstructionProjects: false,
        temporaryTrafficManagement: false,
        trafficSignalProjects: false,
        vulnerableRoadUser: false
    };
    if (objQulificationPre.selectedAuditorExpertiseIds != null){
        objQulificationPre.selectedAuditorExpertiseIds.forEach((y) => {
            switch (y) {
                case 1:
                    objExpert.constructionProject = true;
                    break;
                case 2:
                    objExpert.reconstructionProjects = true;
                    break;
                case 3:
                    objExpert.intersectionProjects = true;
                    break;
                case 4:
                    objExpert.localAreaTrafficManagementProjects = true;
                    break;
                case 5:
                    objExpert.vulnerableRoadUser = true;
                    break;
                case 6:
                    objExpert.trafficSignalProjects = true;
                    break;
                case 7:
                    objExpert.temporaryTrafficManagement = true;
                    break;
                case 8:
                    objExpert.developmentPlanning = true;
                    break;
                case 9:
                    objExpert.designConstructionProjects = true;
                    break;
                case 10:
                    objExpert.crashInvestigation = true;
                    break;
            }
        })
    }

    const objExperience: ExperianceRes = {
        bsSummary: objQulificationPre.bsSummary,
        behaviouralScience: objQulificationPre.behaviouralScience.toString(),
        eligibilityCriteria: objQulificationPre.eligibilityCriteriaExperienceId.toString(),
        experienceSkillsSummary: objQulificationPre.experienceSkillsSummary,
        mitigation: objQulificationPre.mitigation,
        otherRelevantExperience: objQulificationPre.otherRelevantExperience,
        qualifications: objQulificationPre.qualifications,
        roadDesign: objQulificationPre.roadDesign.toString(),
        rdSummary: objQulificationPre.rdSummary,
        roadSafetyEngineering: objQulificationPre.roadSafetyEngineering.toString(),
        totalExperience: objQulificationPre.totalYearsExperience.toString(),
        trSummary: objQulificationPre.trSummary,
        trafficEngineering: objQulificationPre.trafficEngineering.toString(),
        ttmSummary: objQulificationPre.ttmSummary,
        trafficTransportManagement: objQulificationPre.trafficTransportManagement.toString(),
        workExperienceSummary: objQulificationPre.workExperienceSummary != null ? objQulificationPre.workExperienceSummary.toString() : ""
    };


    const titleName = await TitleIdtoValue(objAuditor.titleId)
    objAuditorPersonalDetails = {
        txtAuditorId: objAuditor.auditorId,
        txtTitle: titleName,
        txtFirstName: objAuditor.firstName,
        txtMiddleName: objAuditor.middleName,
        txtLastName: objAuditor.lastName,
        txtEmail: objAuditor.email,
        txtApplyLevel: objAuditor.applyingLevel == null ? objAuditor.previousAuditorLevel.toString() : objAuditor.applyingLevel.toString(),
        txtPassword: "password",
    }
    const StateName = await StateIdtoValue(objAuditorBusinessPre.orgStateId)
    objAuditorBusinessDetails = {
        txtAuditorId: objAuditor.auditorId,
        txtCompanyName: objAuditor.orgName,
        txtBranchName: objAuditor.orgBranch,
        txtDesignation: objAuditor.orgPosition,
        txtOfficePhone: objContactDetails.workPhone,
        txtMobilePhone: objContactDetails.mobile,
        txtHomePhone: objContactDetails.homePhone,
        txtSkypeId: objContactDetails.skype,
        txtWorkEmail: objContactDetails.workEmail,
        txtPersonalEmail: objContactDetails.personalEmail,
        txtPreferredContact: objContactDetails.preferContactMethod.map(function(e){return e.toString()}),
        txtOfficeAddress: objAuditor.orgAddress,
        txtSuburb: objAuditor.orgSuburb,
        txtState: StateName,
        txtPostcode: objAuditor.orgPostcode,
        txtCountry: objAuditor.orgCountryId ? objAuditor.orgCountryId.toString() : "",
    }
    objAuditorRegion = objRegion
    objAuditorRegion.txtAuditorId = objAuditor.auditorId;

    objCourses.map((item: CoursesRes) => {
        objAuditorRoadSafetyCourses.push({
            id: item.id,
            courseId: item.courseId ? item.courseId : 0,
            courseProvider: item.courseProvider,
            courseName: item.courseName,
            courseDate: moment(item.dateComplete).format("DD-MM-YYYY"),
            location: item.courseLocation,
            certificateNumber: item.courseCertNum,
            providerEmail: item.courseProviderEmail,
            courseTentativeClassification: item.courseTentativeClassification,
            isVerified: item.isVerified
        })
    });

    objAudits.map((item: Audits) => {
        objAuditorRoadSafetyAudit.push({
            auditId: item.auditId,
            titleOfAudit: item.auditTitle == "" ? "-" : item.auditTitle,
            finalSignoffDate: moment(item.auditFinalSignoffDate).format("DD-MM-YYYY") == "01-01-1900" ? "-" : moment(item.auditFinalSignoffDate).format("DD-MM-YYYY"),
            teamLeaderName: item.teamLeaderName == "" ? "-" : item.teamLeaderName,
            clientCompany: item.auditClientCompany,
            auditStatusId:item.auditStatusId.toString(),
            isVerify:item.linked,
            elapseDate: moment(item.elapseDate).format("DD-MM-YYYY") == "01-01-1900" ? moment(item.auditFinalSignoffDate).format("DD-MM-YYYY") : moment(item.elapseDate).format("DD-MM-YYYY"),
            isTfNswProject: item.isTfNswProject,
            stageOfAudit: item.auditStageName
        })
    })

    objQualification = {
        auditorId: objAuditor.auditorId,
        totalExperience: objExperience.totalExperience,
        rdSummary: objQulificationPre.rdSummary,
        trSummary: objQulificationPre.trSummary,
        ttmSummary: objQulificationPre.ttmSummary,
        rseSummary: objQulificationPre.rseSummary,
        bsSummary: objQulificationPre.bsSummary,
        roadDesign: objExperience.roadDesign,
        trafficEngineering: objExperience.trafficEngineering,
        trafficTransportManagement: objExperience.trafficTransportManagement,
        roadSafetyEngineering: objExperience.roadSafetyEngineering,
        behaviouralScience: objExperience.behaviouralScience,
        workExperienceSummary: objExperience.workExperienceSummary,
        otherRelevantExperience: objExperience.otherRelevantExperience,
        qualifications: objExperience.qualifications,
        experienceSkillsSummary: objExperience.experienceSkillsSummary,
        mitigation: objExperience.mitigation,
        constructionProject: objExpert.constructionProject,
        reconstructionProjects: objExpert.reconstructionProjects,
        intersectionProjects: objExpert.intersectionProjects,
        localAreaTrafficManagementProjects: objExpert.localAreaTrafficManagementProjects,
        vulnerableRoadUser: objExpert.vulnerableRoadUser,
        trafficSignalProjects: objExpert.trafficSignalProjects,
        temporaryTrafficManagement: objExpert.temporaryTrafficManagement,
        developmentPlanning: objExpert.developmentPlanning,
        designConstructionProjects: objExpert.designConstructionProjects,
        crashInvestigation: objExpert.crashInvestigation,
        eligibilityCriteria: objExperience.eligibilityCriteria,
    }

    const objAuditorProfile: AuditorProfile = {
        personalDetails: objAuditorPersonalDetails,
        businessDetails: objAuditorBusinessDetails,
        auditorRegion: objAuditorRegion,
        roadSafetyCourses: objAuditorRoadSafetyCourses,
        roadSafetyAudit: objAuditorRoadSafetyAudit,
        qualification: objQualification,
        Editable: true, //objAuditor.editable,
        AD: AD,
        BD: BD,
        AVA: AVA,
        QE: QE,
        RSA: RSA,
        RSC: RSC,
        AtotalPages:result.auditorProfileForAuditorRoleRes.vwAuditorAuditRess.totalPages,
        AED:result.auditorProfileForAuditorRoleRes.auditorPersonalDetailsRes.auditorsStateRes.certExpiryDate
    }
    //console.log(objAuditorProfile)
    return objAuditorProfile;//return the object
}

const TitleIdtoValue = async(id:number) =>{
    let TitleName = ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/usertitles`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objUserTitle: UserTitles[] = result.userTitles; //store the result in userTitles array
    objUserTitle.map((r)=>{
        if(r.titleId == id ){
            TitleName =  r.titleName
        }
    })
    return TitleName
}

const StateIdtoValue = async(id:number) =>{
    let StateName = ''
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/CommonCodes/states`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objStates: States[] = result.states; //store the result in userTitles array
    objStates.map((r)=>{
        if(r.stateId == id ){
            StateName =  r.stateName
        }
    })
    return StateName
}

const SendEnquiry = async (props:SendMessageState) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/SendEnquiryToAuditor`; //complete url
    const Data = {
        auditorId: props.auditorId,
        name: props.fullName,
        email: props.email,
        company: props.company,
        position: props.position,
        phone: props.phone,
        message: props.message
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:Data}); //call the api using ApiCall method
    const blSendStatus: boolean = result.enquiryToAuditorSent; //store the result in userTitles array

    return blSendStatus;//return the array
}

const SearchApiCall = async (data?:SearchReq) =>{
    //eslint-disable-next-line
    let SearchRsult:SearchRes
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/SearchAuditors`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:data}); //call the api using ApiCall method
    //eslint-disable-next-line
    SearchRsult = result; //store the result in userTitles array

    return SearchRsult
}

const fetchAuditorAuditSearch = async (auditorId: number, pageNumber: number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorAccreditation/AuditorAuditsWithPagination`; //complete url
    const data = JSON.stringify({
        auditorId: auditorId,
        countryStateId: 1,
        pageSize: 10,
        pageNumStartingZero: pageNumber,
        isValidAudits:true
    })
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    //eslint-disable-next-line
    const objAudits: AuditorAuditRess[] = result.auditorAuditRess.lstVwAuditorAuditRes; //store the result in userTitles array
    return objAudits

}

export {fetchAuditorSearch,SendEnquiry,SearchApiCall,fetchAuditorAuditSearch}
