import {Grid} from "semantic-ui-react";
import OutLineButton from "../../components/Buttons/OutlIneButton";
import React from "react";
import {ArrNotificationMenuProps, NotificationMenuProps} from "../../types/ArrNotificationMenuProps";

const NotificationMenu = (props: ArrNotificationMenuProps) => {
    return (
        <Grid>
            {
                props.Data.map((r: NotificationMenuProps, index) => (
                    <Grid.Row key={index} columns={3}>
                        <Grid.Column width={10} style={{justifyContent:'start', display:'flex', marginTop:'8px', marginBottom:'8px'}}>
                            {r.text}
                        </Grid.Column>
                        <Grid.Column width={2} style={{justifyContent:'end', display:'flex', marginTop:'8px', marginBottom:'8px'}}>
                            {r.value}
                        </Grid.Column>
                        <Grid.Column width={3} className={'button-flex'}>
                            <OutLineButton id={'cmbApplyLevelReview'} onClick={r.onClick} style={{width: '150px', height:'36px'}}
                                           color='blue' className={"background-transaparent"} text={'View'}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ))
            }
        </Grid>
    )

}

export default NotificationMenu