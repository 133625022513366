import React from "react";
import Position from "../../types/Position";
import TextBoxProps from "../../types/TextBoxProps";
import {Grid} from "semantic-ui-react";
import TextBoxes from "../../components/TextBoxes";
import EmailInputBox from "../../components/TextBoxes/EmailInputBox";
import DefaultTextBox from "../../components/TextBoxes/DefaultTextBox";
import MultilineTextBox from "../../components/TextBoxes/MultilineTextBox";

interface NameWithTextProps extends TextBoxProps{
    labelText: string;
    labelPosition: Position.Top | Position.Left;
    required?: boolean;
}
const NameWithMultiline = (props:NameWithTextProps) => {
    if(props.labelPosition == Position.Top){
        return (
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16} textAlign={"left"}>
                        {props.labelText ? (<span style={{display:'inline-flex'}}>{props.labelText}<p style={{color:'red'}}>{props.required ? '*':''}</p></span>) : null}
                        <MultilineTextBox
                            id={props.id}
                            placeholder={props.placeholder}
                            style={props.style}
                            className={props.className}
                            disabled={props.disabled}
                            value={props.value}
                            onChange={props.onChange}
                            onClick={props.onClick}
                            fluid={props.fluid}
                            onKeydown={props.onKeydown}
                            onLostFocus={props.onLostFocus}
                            onerror={props.onError}
                            wordCount={props.wordCount}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        );
    }else{
        return (
            <Grid>
                <Grid.Row columns={2} verticalAlign={'middle'}>
                    <Grid.Column width={4} textAlign={"center"}>
                        <span style={{display:'inline-flex'}}>{props.labelText}<p style={{color:'red'}}>{props.required ? '*':''}</p></span>
                    </Grid.Column>
                    <Grid.Column width={12}>
                        <MultilineTextBox
                            id={props.id}
                            placeholder={props.placeholder}
                            style={props.style}
                            className={props.className}
                            disabled={props.disabled}
                            value={props.value}
                            onChange={props.onChange}
                            onerror={props.onError}
                            onClick={props.onClick}
                            fluid={props.fluid}
                            onKeydown={props.onKeydown}
                            onLostFocus={props.onLostFocus}
                            wordCount={props.wordCount}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        )
    }

}

export default NameWithMultiline