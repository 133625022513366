import React, { useEffect, useState } from "react";
import { Grid, Icon, Popup } from "semantic-ui-react";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import Dropdowns from "../../../framework/components/DropDowns";
import {
  AuditorProfile,
  PersonalDetailsValueProps,
} from "../profile/DataModal";
import { AddOrUpdateAuditorDetails } from "./DashboardController";
import {
  getSessionCookie,
  setSessionCookie,
} from "../../../framework/common/SessionHandler";
import Modal from "../../../framework/components/Modals";
import errorToast from "../../../framework/components/Toast/ErrorToast";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";
import waringImage from "../../../assets/images/icons/warning.png";
import eligibilityCheck, {
  IEligibilityCheck,
} from "../../../common/eligibilityCheck";
import successToast from "../../../framework/components/Toast/SuccessToast";
import { LoadAuditor } from "../../../framework/common/Sign";
import AddNewAuditView from "../../../framework/widgets/TableWithBackgrounds/AddNew AuditView";
import { fetchAuditor } from "../profile/ProfileController";
import {
  ValidationRequestProps,
  ValidationResponseProps,
  ValidationRuleProps,
} from "../../../framework/types/ValidationProps";
import Validations from "../../../framework/common/Validations";
import ValidationRuleType from "../../../framework/types/ValidationRuleType";
import Modals from "../../../framework/components/Modals";
import { HashLink } from "react-router-hash-link";

interface Props {
  auditor?: PersonalDetailsValueProps;
}

const ApplyForLevelReview = ({ auditor }: Props) => {
  const session = getSessionCookie();
  const [disable, setDisable] = useState(true);
  const [eligibility, setEligibility] = useState<IEligibilityCheck>({
    CourseSuccess: false,
    AuditSuccess: false,
    experianceSuccess: false,
  });
  const [error, setError] = useState("");
  const [errorPop, setErrorPop] = useState(false);
  const [applyingLevelVisibility, setApplyingLevelVisibility] = useState(true);
  const [applyLevel, setApplyLevel] = useState(
    session.status == 3 ? "" : auditor?.txtApplyLevel
  );
  const [errorList, setErrorList] = React.useState<ValidationResponseProps[]>([
    {},
  ] as ValidationResponseProps[]);
  //eslint-disable-next-line
  let failedResult: ValidationResponseProps[] = [];
  const [option, setOPtion] = useState([
    { key: -1, text: "Select", value: "-1" },
    {
      key: 1,
      text: "Auditor (Level 1)",
      value: "1",
    },
    {
      key: 2,
      text: "Senior Auditor (Level 2)",
      value: "2",
    },
    { key: 3, text: "Lead Auditor (Level 3)", value: "3" },
  ]);
  const [open, setOpen] = useState(false);
  const [descriptionHeader, setDescriptionHeader] = useState("");
  const [descriptionBody, setDescriptionBody] = useState("");

  useEffect(() => {
    setApplyLevel(auditor?.txtApplyLevel);
    console.log("auditor?.txtApplyLevel: ", auditor);

    //Existing code snippet
    // const optionsVar = option.filter(
    //   (line) => line.value !== auditor?.txtApplyLevel
    // );

    //Suggested code snippet - Supun
    // const optionsVar = option.filter(
    //     (line) => line.value !== auditor?.txtCurrentLevel
    //   );

    // console.log("auditor?.txtApplyLevel, Filtered: ", optionsVar);
    // setOPtion(optionsVar);

    //This will eliminate lower levels as it makes the level downgrade impossible.
    const currentLevel = parseInt(auditor?.txtCurrentLevel ?? "0", 10);
    const filteredOptions  = option.filter((line) => {
      const level = parseInt(line.value, 10);
      return level > currentLevel;
    });
    const optionsVar = [{ key: -1, text: "Select", value: "-1" }, ...filteredOptions];
    setOPtion(optionsVar);

    //This will hide whole Level applying section for level 3 auditors.
    //If you want to enable this when you have Level Four auditors, Please change this 3 to 4.
    if(auditor?.txtCurrentLevel == '3'){
      setApplyingLevelVisibility(false);
    }
  }, [auditor]);

  const ApplyForLevel = async () => {
    if (
      auditor?.txtApplyLevel != undefined &&
      applyLevel != undefined &&
      applyLevel > "0"
    ) {
      if (!(await ValidityCheck())) {
        setErrorPop(true);
        setOpen(false);
        return;
      }
      if (auditor?.txtApplyLevel > applyLevel) {
        setDescriptionHeader("Are you sure ?");
        setDescriptionBody(
          `Level downgrade will be effective immediately. You will need to re-apply for assessment if you wish to upgrade level. Are you sure you want to proceed?`
        );
        setOpen(true);
      } else {
        setDescriptionHeader("You are applying for Level Review :");
        setDescriptionBody(
          `Please ensure you meet the criteria mention in FAQs. Click Yes if you are happy to submit your application, otherwise click NO and reset the value in the field 'Apply for Level Review' to the previous value before saving again !`
        );
        setOpen(true);
        //PostDataToAPI();
      }
    } else {
      errorToast("Apply level can't be empty");
    }
  };

  const ValidityCheck = async () => {
    let result = false;
    const auditorProfile = await fetchAuditor(
      auditor?.txtAuditorId ? auditor?.txtAuditorId : 0,
      0
    );
    if (auditorProfile) {
      result = ValidateDataforSubmit(auditorProfile);
    }
    return result;
  };

  const ValidateDataforSubmit = (auditorProfile: AuditorProfile) => {
    let result = true;
    let ValidateProps: ValidationRequestProps = {} as ValidationRequestProps;

    //qualification details
    if (auditorProfile.qualification != undefined) {
      //Qualification Validation
      if (auditorProfile.qualification.qualifications != undefined) {
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        rule = [
          {
            Rule: "100",
            Type: ValidationRuleType.MAXLENTH,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.qualifications,
          fieldType: "text",
          fieldName: "Qualifications",
          required: true,
          fieldId: "qualifications",
          rules: rule,
        } as ValidationRequestProps;
        const qualificationsVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!qualificationsVal.Valid) {
          result = false;
          failedResult.push(qualificationsVal);
          setErrorList(failedResult);
        }
      }

      //work experience details
      if (
        auditorProfile.qualification.workExperienceSummary != undefined &&
        auditorProfile.qualification.workExperienceSummary != ""
      ) {
        console.log("WES", auditorProfile.qualification.workExperienceSummary);
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        rule = [
          {
            Rule: "2000",
            Type: ValidationRuleType.MAXLENTH,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.workExperienceSummary,
          fieldType: "text",
          fieldName: "Work Experience Summary",
          required: true,
          fieldId: "workExperienceSummary",
          rules: rule,
        } as ValidationRequestProps;
        const workExperienceSummaryVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!workExperienceSummaryVal.Valid) {
          result = false;
          failedResult.push(workExperienceSummaryVal);
          setErrorList(failedResult);
        }
      } else {
        if (
          applyLevel != undefined &&
          (applyLevel == "1" || applyLevel == "2" || applyLevel == "3")
        ) {
          const res: ValidationResponseProps = {
            Valid: false,
            Msg: "Work Experience Summary is required for selected auditor level",
          } as ValidationResponseProps;
          failedResult.push(res);
          result = false;
          setErrorList(failedResult);
        }
      }

      //total experience Validation
      if (
        auditorProfile.qualification.totalExperience != undefined &&
        parseInt(auditorProfile.qualification.totalExperience) > 0
      ) {
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        const maxExp = Math.max(
          parseInt(
            auditorProfile.qualification.roadDesign != undefined
              ? auditorProfile.qualification.roadDesign
              : "0"
          ),
          parseInt(
            auditorProfile.qualification.trafficEngineering != undefined
              ? auditorProfile.qualification.trafficEngineering
              : "0"
          ),
          parseInt(
            auditorProfile.qualification.trafficTransportManagement != undefined
              ? auditorProfile.qualification.trafficTransportManagement
              : "0"
          ),
          parseInt(
            auditorProfile.qualification.roadSafetyEngineering != undefined
              ? auditorProfile.qualification.roadSafetyEngineering
              : "0"
          ),
          parseInt(
            auditorProfile.qualification.behaviouralScience != undefined
              ? auditorProfile.qualification.behaviouralScience
              : "0"
          )
        );

        const sumExp =
          parseInt(
            auditorProfile.qualification.roadDesign != undefined
              ? auditorProfile.qualification.roadDesign
              : "0"
          ) +
          parseInt(
            auditorProfile.qualification.trafficEngineering != undefined
              ? auditorProfile.qualification.trafficEngineering
              : "0"
          ) +
          parseInt(
            auditorProfile.qualification.trafficTransportManagement != undefined
              ? auditorProfile.qualification.trafficTransportManagement
              : "0"
          ) +
          parseInt(
            auditorProfile.qualification.roadSafetyEngineering != undefined
              ? auditorProfile.qualification.roadSafetyEngineering
              : "0"
          ) +
          parseInt(
            auditorProfile.qualification.behaviouralScience != undefined
              ? auditorProfile.qualification.behaviouralScience
              : "0"
          );

        rule = [
          {
            Rule: "^\\d+$",
            Type: ValidationRuleType.REGEX,
          },
          {
            Rule: `${auditorProfile.qualification.totalExperience} <= 80`,
            Type: ValidationRuleType.CUSTOM,
          },
          {
            Rule: `${maxExp} <= ${auditorProfile.qualification.totalExperience} && ${auditorProfile.qualification.totalExperience} <= ${sumExp}`,
            Type: ValidationRuleType.CUSTOM,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.totalExperience,
          fieldType: "text",
          rules: rule,
          fieldName: "Total Experience",
          required: true,
          fieldId: "totalExperience",
        } as ValidationRequestProps;
        const txtPostcodeVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!txtPostcodeVal.Valid) {
          result = false;
          failedResult.push(txtPostcodeVal);
          setErrorList(failedResult);
        }
      } else {
        const res: ValidationResponseProps = {
          Valid: false,
          Msg: "Total Experience is required",
        } as ValidationResponseProps;
        failedResult.push(res);
        result = false;
        setErrorList(failedResult);
      }

      //work experience and skills summery details
      if (
        auditorProfile.qualification.experienceSkillsSummary != undefined &&
        auditorProfile.qualification.experienceSkillsSummary != ""
      ) {
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        rule = [
          {
            Rule: "2000",
            Type: ValidationRuleType.MAXLENTH,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.experienceSkillsSummary,
          fieldType: "text",
          fieldName: "Experience Skills Summary",
          required: true,
          fieldId: "experienceSkillsSummary",
          rules: rule,
        } as ValidationRequestProps;
        const experienceSkillsSummaryVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!experienceSkillsSummaryVal.Valid) {
          result = false;
          failedResult.push(experienceSkillsSummaryVal);
          setErrorList(failedResult);
        }
      } else {
        if (
          applyLevel != undefined &&
          (applyLevel == "2" || applyLevel == "3")
        ) {
          const res: ValidationResponseProps = {
            Valid: false,
            Msg: "Work Experience / Skill Summary is required for selected auditor level",
          } as ValidationResponseProps;
          failedResult.push(res);
          result = false;
          setErrorList(failedResult);
        }
      }

      //Mitigation details
      if (
        auditorProfile.qualification.mitigation != undefined &&
        auditorProfile.qualification.mitigation != ""
      ) {
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        rule = [
          {
            Rule: "2000",
            Type: ValidationRuleType.MAXLENTH,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.mitigation,
          fieldType: "text",
          fieldName: "Road Safety identification and mitigation",
          required: true,
          fieldId: "mitigation",
          rules: rule,
        } as ValidationRequestProps;
        const mitigationVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!mitigationVal.Valid) {
          result = false;
          failedResult.push(mitigationVal);
          setErrorList(failedResult);
        }
      } else {
        if (
          applyLevel != undefined &&
          (applyLevel == "2" || applyLevel == "3")
        ) {
          const res: ValidationResponseProps = {
            Valid: false,
            Msg: "Road Safety identification and mitigation is required for selected auditor level",
          } as ValidationResponseProps;
          failedResult.push(res);
          result = false;
          setErrorList(failedResult);
        }
      }

      //Relevant Experiences Validation
      if (
        auditorProfile.qualification.roadDesign != undefined &&
        parseInt(auditorProfile.qualification.roadDesign) > 0 &&
        (auditorProfile.qualification.rdSummary == undefined ||
          auditorProfile.qualification.rdSummary.length <= 0)
      ) {
        ValidateProps = {
          value: auditorProfile.qualification.rdSummary,
          fieldType: "text",
          fieldName: "Road Design Summary",
          required: true,
          fieldId: "roadDesignSummary",
        } as ValidationRequestProps;
        const roadDesignVal: ValidationResponseProps =
          Validations(ValidateProps);
        result = false;
        failedResult.push(roadDesignVal);
        setErrorList(failedResult);
      }
      if (
        auditorProfile.qualification.trafficEngineering != undefined &&
        parseInt(auditorProfile.qualification.trafficEngineering) > 0 &&
        (auditorProfile.qualification.trSummary == undefined ||
          auditorProfile.qualification.trSummary.length <= 0)
      ) {
        ValidateProps = {
          value: auditorProfile.qualification.trSummary,
          fieldType: "text",
          fieldName: "Traffic Engineering Summary",
          required: true,
          fieldId: "trSummary",
        } as ValidationRequestProps;
        const roadDesignVal: ValidationResponseProps =
          Validations(ValidateProps);
        result = false;
        failedResult.push(roadDesignVal);
        setErrorList(failedResult);
      }
      if (
        auditorProfile.qualification.trafficTransportManagement != undefined &&
        parseInt(auditorProfile.qualification.trafficTransportManagement) > 0 &&
        (auditorProfile.qualification.ttmSummary == undefined ||
          auditorProfile.qualification.ttmSummary.length <= 0)
      ) {
        ValidateProps = {
          value: auditorProfile.qualification.ttmSummary,
          fieldType: "text",
          fieldName: "Traffic Transport Management Summary",
          required: true,
          fieldId: "ttmSummary",
        } as ValidationRequestProps;
        const roadDesignVal: ValidationResponseProps =
          Validations(ValidateProps);
        result = false;
        failedResult.push(roadDesignVal);
        setErrorList(failedResult);
      }
      if (
        auditorProfile.qualification.roadSafetyEngineering != undefined &&
        parseInt(auditorProfile.qualification.roadSafetyEngineering) > 0 &&
        (auditorProfile.qualification.rseSummary == undefined ||
          auditorProfile.qualification.rseSummary.length <= 0)
      ) {
        ValidateProps = {
          value: auditorProfile.qualification.rseSummary,
          fieldType: "text",
          fieldName: "Road Safety Engineering Summary",
          required: true,
          fieldId: "rseSummary",
        } as ValidationRequestProps;
        const roadDesignVal: ValidationResponseProps =
          Validations(ValidateProps);
        result = false;
        failedResult.push(roadDesignVal);
        setErrorList(failedResult);
      }
      if (
        auditorProfile.qualification.behaviouralScience != undefined &&
        parseInt(auditorProfile.qualification.behaviouralScience) > 0 &&
        (auditorProfile.qualification.bsSummary == undefined ||
          auditorProfile.qualification.bsSummary.length <= 0)
      ) {
        ValidateProps = {
          value: auditorProfile.qualification.bsSummary,
          fieldType: "text",
          fieldName: "Behavioural Science Summary",
          required: true,
          fieldId: "bsSummary",
        } as ValidationRequestProps;
        const roadDesignVal: ValidationResponseProps =
          Validations(ValidateProps);
        result = false;
        failedResult.push(roadDesignVal);
        setErrorList(failedResult);
      }
      if (
        auditorProfile.qualification.roadDesign != undefined &&
        parseInt(auditorProfile.qualification.roadDesign) > 0 &&
        auditorProfile.qualification.trafficEngineering != undefined &&
        parseInt(auditorProfile.qualification.trafficEngineering) > 0 &&
        auditorProfile.qualification.trafficTransportManagement != undefined &&
        parseInt(auditorProfile.qualification.trafficTransportManagement) > 0 &&
        auditorProfile.qualification.roadSafetyEngineering != undefined &&
        parseInt(auditorProfile.qualification.roadSafetyEngineering) > 0 &&
        auditorProfile.qualification.behaviouralScience != undefined &&
        parseInt(auditorProfile.qualification.behaviouralScience) > 0
      ) {
        if (
          auditorProfile.qualification.roadDesign != undefined &&
          auditorProfile.qualification.roadDesign != "0"
        ) {
          let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
          rule = [
            {
              Rule: "^\\d+$",
              Type: ValidationRuleType.REGEX,
            },
            {
              Rule: `${auditorProfile.qualification.roadDesign} <= 80`,
              Type: ValidationRuleType.CUSTOM,
            },
          ];
          ValidateProps = {
            value: auditorProfile.qualification.roadDesign,
            fieldType: "text",
            rules: rule,
            fieldName: "Road Design",
            required: true,
            fieldId: "roadDesign",
          } as ValidationRequestProps;
          const roadDesignVal: ValidationResponseProps =
            Validations(ValidateProps);
          if (!roadDesignVal.Valid) {
            result = false;
            failedResult.push(roadDesignVal);
            setErrorList(failedResult);
          }
        }

        if (
          auditorProfile.qualification.trafficEngineering != undefined &&
          auditorProfile.qualification.trafficEngineering != "0"
        ) {
          let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
          rule = [
            {
              Rule: "^\\d+$",
              Type: ValidationRuleType.REGEX,
            },
            {
              Rule: `${auditorProfile.qualification.trafficEngineering} <= 80`,
              Type: ValidationRuleType.CUSTOM,
            },
          ];
          ValidateProps = {
            value: auditorProfile.qualification.trafficEngineering,
            fieldType: "text",
            rules: rule,
            fieldName: "Traffic Engineering",
            required: true,
            fieldId: "trafficEngineering",
          } as ValidationRequestProps;
          const trafficEngineeringVal: ValidationResponseProps =
            Validations(ValidateProps);
          if (!trafficEngineeringVal.Valid) {
            result = false;
            failedResult.push(trafficEngineeringVal);
            setErrorList(failedResult);
          } else {
            if (
              auditorProfile.qualification.trSummary == undefined ||
              auditorProfile.qualification.trSummary.length <= 0
            ) {
              result = false;
              failedResult.push(trafficEngineeringVal);
              setErrorList(failedResult);
            }
          }
        }

        if (
          auditorProfile.qualification.trafficTransportManagement !=
            undefined &&
          auditorProfile.qualification.trafficTransportManagement != "0"
        ) {
          let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
          rule = [
            {
              Rule: "^\\d+$",
              Type: ValidationRuleType.REGEX,
            },
            {
              Rule: `${auditorProfile.qualification.trafficTransportManagement} <= 80`,
              Type: ValidationRuleType.CUSTOM,
            },
          ];
          ValidateProps = {
            value: auditorProfile.qualification.trafficTransportManagement,
            fieldType: "text",
            rules: rule,
            fieldName: "Traffic Transport Management",
            required: true,
            fieldId: "trafficTransportManagement",
          } as ValidationRequestProps;
          const trafficTransportManagementVal: ValidationResponseProps =
            Validations(ValidateProps);
          if (!trafficTransportManagementVal.Valid) {
            result = false;
            failedResult.push(trafficTransportManagementVal);
            setErrorList(failedResult);
          } else {
            if (
              auditorProfile.qualification.ttmSummary == undefined ||
              auditorProfile.qualification.ttmSummary.length <= 0
            ) {
              result = false;
              failedResult.push(trafficTransportManagementVal);
              setErrorList(failedResult);
            }
          }
        }

        if (
          auditorProfile.qualification.roadSafetyEngineering != undefined &&
          auditorProfile.qualification.roadSafetyEngineering != "0"
        ) {
          let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
          rule = [
            {
              Rule: "^\\d+$",
              Type: ValidationRuleType.REGEX,
            },
            {
              Rule: `${auditorProfile.qualification.roadSafetyEngineering} <= 80`,
              Type: ValidationRuleType.CUSTOM,
            },
          ];
          ValidateProps = {
            value: auditorProfile.qualification.roadSafetyEngineering,
            fieldType: "text",
            rules: rule,
            fieldName: "Road Safety Engineering",
            required: true,
            fieldId: "roadSafetyEngineering",
          } as ValidationRequestProps;
          const roadSafetyEngineeringVal: ValidationResponseProps =
            Validations(ValidateProps);
          if (!roadSafetyEngineeringVal.Valid) {
            result = false;
            failedResult.push(roadSafetyEngineeringVal);
            setErrorList(failedResult);
          } else {
            if (
              auditorProfile.qualification.rseSummary == undefined ||
              auditorProfile.qualification.rseSummary.length <= 0
            ) {
              result = false;
              failedResult.push(roadSafetyEngineeringVal);
              setErrorList(failedResult);
            }
          }
        }

        if (
          auditorProfile.qualification.behaviouralScience != undefined &&
          auditorProfile.qualification.behaviouralScience != "0"
        ) {
          let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
          rule = [
            {
              Rule: "^\\d+$",
              Type: ValidationRuleType.REGEX,
            },
            {
              Rule: `${auditorProfile.qualification.behaviouralScience} <= 80`,
              Type: ValidationRuleType.CUSTOM,
            },
          ];
          ValidateProps = {
            value: auditorProfile.qualification.behaviouralScience,
            fieldType: "text",
            rules: rule,
            fieldName: "Behavioural Science",
            required: true,
            fieldId: "behaviouralScience",
          } as ValidationRequestProps;
          const behaviouralScienceVal: ValidationResponseProps =
            Validations(ValidateProps);
          if (!behaviouralScienceVal.Valid) {
            result = false;
            failedResult.push(behaviouralScienceVal);
            setErrorList(failedResult);
          } else {
            if (
              auditorProfile.qualification.bsSummary == undefined ||
              auditorProfile.qualification.bsSummary.length <= 0
            ) {
              result = false;
              failedResult.push(behaviouralScienceVal);
              setErrorList(failedResult);
            }
          }
        }
      } else {
        if (
          (auditorProfile.qualification.roadDesign == undefined ||
            parseInt(auditorProfile.qualification.roadDesign) <= 0) &&
          (auditorProfile.qualification.trafficEngineering == undefined ||
            parseInt(auditorProfile.qualification.trafficEngineering) <= 0) &&
          (auditorProfile.qualification.trafficTransportManagement ==
            undefined ||
            parseInt(auditorProfile.qualification.trafficTransportManagement) <=
              0) &&
          (auditorProfile.qualification.roadSafetyEngineering == undefined ||
            parseInt(auditorProfile.qualification.roadSafetyEngineering) <=
              0) &&
          (auditorProfile.qualification.behaviouralScience == undefined ||
            parseInt(auditorProfile.qualification.behaviouralScience) <= 0)
        ) {
          const res: ValidationResponseProps = {
            Valid: false,
            Msg: "At least one relevant experience is required",
          } as ValidationResponseProps;
          failedResult.push(res);
          result = false;
          setErrorList(failedResult);
        }
      }

      //Other relevant experience Validation
      if (auditorProfile.qualification.otherRelevantExperience != undefined) {
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        rule = [
          {
            Rule: "100",
            Type: ValidationRuleType.MAXLENTH,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.otherRelevantExperience,
          fieldType: "text",
          fieldName: "Other Relevant Experience",
          required: true,
          fieldId: "otherRelevantExperience",
          rules: rule,
        } as ValidationRequestProps;
        const otherRelevantExperienceVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!otherRelevantExperienceVal.Valid) {
          result = false;
          failedResult.push(otherRelevantExperienceVal);
          setErrorList(failedResult);
        }
      }

      //Work Experience Declaration details
      if (auditorProfile.qualification.eligibilityCriteria != undefined) {
        let rule: ValidationRuleProps[] = {} as ValidationRuleProps[];
        rule = [
          {
            Rule: `(${auditorProfile.qualification.totalExperience} < 5 && ${auditorProfile.qualification.eligibilityCriteria} == '1') || (${auditorProfile.qualification.totalExperience} <= 6 && ${auditorProfile.qualification.totalExperience} >= 5 && ${auditorProfile.qualification.eligibilityCriteria} == '2') || (${auditorProfile.qualification.totalExperience} >= 7 && ${auditorProfile.qualification.eligibilityCriteria} == '3')`,
            Type: ValidationRuleType.CUSTOM,
          },
        ];
        ValidateProps = {
          value: auditorProfile.qualification.eligibilityCriteria,
          fieldType: "dropdown",
          fieldName: "Work Experience Declaration",
          required: true,
          fieldId: "eligibilityCriteria",
          rules: rule,
        } as ValidationRequestProps;
        const eligibilityCriteriaVal: ValidationResponseProps =
          Validations(ValidateProps);
        if (!eligibilityCriteriaVal.Valid) {
          result = false;
          failedResult.push(eligibilityCriteriaVal);
          setErrorList(failedResult);
        }
      } else {
        const res: ValidationResponseProps = {
          Valid: false,
          Msg: "Work Experience Declaration is required",
        } as ValidationResponseProps;
        failedResult.push(res);
        result = false;
        setErrorList(failedResult);
      }
    } else {
      const res: ValidationResponseProps = {
        Valid: false,
        Msg: "Please fill required fields in qualification details",
      } as ValidationResponseProps;
      failedResult.push(res);
      result = false;
      setErrorList(failedResult);
    }
    return result;
  };

  const OnYesClick = () => {
    PostDataToAPI();
    setOpen(false);
  };

  // const onChange = async (e: any, data: any) => {
  //   setError("");
  //   if (data.value > "0") {
  //     setDisable(false);
  //     if (applyLevel != undefined && data.value > applyLevel) {
  //       const isEligible = await eligibilityCheck(
  //         data.value,
  //         auditor?.txtAuditorId
  //       );
  //       console.log("Apply Page", isEligible);
  //       if (
  //         !isEligible.CourseSuccess ||
  //         !isEligible.AuditSuccess ||
  //         !isEligible.experianceSuccess
  //       ) {
  //         setEligibility(isEligible);
  //         setDisable(true);
  //         setError(
  //           'Eligibility not met for the requested Level, <a href="/faqs" target="_blank" style="text-decoration: underline; cursor: pointer; color: red;">please refer to FAQs.</a>'
  //         );
  //       } else {
  //         setError("");
  //         setEligibility(isEligible);
  //       }
  //     }
  //   } else {
  //     setDisable(true);
  //     setError("");
  //   }
  //   setApplyLevel(data.value);
  // };

    const onChange = async (e: any, data: any) => {
    setError("");
    setDisable(true);
    if (data.value > "0") {
      setDisable(false);
      if (auditor?.txtCurrentLevel != undefined && data.value > auditor?.txtCurrentLevel) {
        const isEligible = await eligibilityCheck(
          data.value,
          auditor?.txtAuditorId
        );
        console.log("Applying Level Eligibility Status: ", isEligible);
        if (
          !isEligible.CourseSuccess ||
          !isEligible.AuditSuccess ||
          !isEligible.experianceSuccess
        ) {
          setEligibility(isEligible);
          setDisable(true);
          setError(
            'Eligibility not met for the requested Level, <a href="/faqs" target="_blank" style="text-decoration: underline; cursor: pointer; color: red;">please refer to FAQs.</a>'
          );
        } else {
          setError("");
          setEligibility(isEligible);
          setDisable(false);
        }
      }
      // else{
      //   setDisable(true);
      //   setError("");
      // }
    } else {
      setDisable(true);
      setError("");
    }
    setApplyLevel(data.value);
  };

  const PostDataToAPI = async () => {
    if (applyLevel != undefined && applyLevel > "0") {
      const state: PersonalDetailsValueProps = {
        txtApplyLevel: applyLevel,
        txtPassword: auditor?.txtPassword,
        txtEmail: auditor?.txtEmail,
        txtTitle: auditor?.txtTitle,
        txtLastName: auditor?.txtLastName,
        txtFirstName: auditor?.txtFirstName,
        txtAuditorId: auditor?.txtAuditorId,
        txtMiddleName: auditor?.txtMiddleName,
        txtCurrentLevel: auditor?.txtApplyLevel,
      };
      const auditorId = await AddOrUpdateAuditorDetails(state);
      if (auditorId.auditorId == auditor?.txtAuditorId) {
        if (descriptionHeader == "Are you sure ?") {
          successToast("Auditor level change is confirmed !");
        } else {
          successToast("Auditor level review application is submitted!");
        }

        setApplyLevel(auditorId.applyingLevel);
        const auditorPromise = LoadAuditor({
          user: {
            userName: auditor?.txtEmail,
            userDisplayName: auditor?.txtFirstName,
            auditorId: auditor?.txtAuditorId,
          },
        });
        Promise.resolve(auditorPromise).then((r) => {
          const session = getSessionCookie();
          setSessionCookie({ ...session, ...r });
          setTimeout(() => {
            window.location.href = "/welcome";
          }, 1500);
        });
      } else if (auditorId.response?.data && auditorId.response.data.error) {
        //Handle Custom 400 errors.
        const exception = auditorId.response.data.error;

        //we can remove this condition if we always display the error details for any exception thrown.
        if (exception?.errorType === "Ineligible applying level") {
          errorToast(exception.detail);
          return;
        } 

        errorToast(exception.detail);
       
      } else {
        // Handle unexpected errors
        errorToast("An unexpected error occurred.");
      }
    } else {
      errorToast("please select apply level");
    }
  };
  return (
    <React.Fragment>
      <Grid>
        {applyingLevelVisibility? (<>
          <Grid.Row>
          <Grid.Column>
            <h3 style={{ display: "inline-flex", color: "#1559A3" }}>
              Apply for level review
            </h3>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={2}>
          <Grid.Column width={12} tablet={16} computer={12} mobile={16}>
            <Dropdowns
              selection={true}
              clearable={true}
              fluid={true}
              id={"ddlevelReview"}
              placeholder={"Select"}
              options={option}
              value={applyLevel}
              onChange={onChange}
              //onerror={error}
              disabled={session.status == 4}
            />
          </Grid.Column>
          <Grid.Column width={2} computer={2} tablet={1}></Grid.Column>
          <Grid.Column
            width={2}
            computer={2}
            tablet={16}
            mobile={16}
            className={"button-flex"}
          >
            <OutLineButton
              id={"cmbApplyLevelReview"}
              onClick={ApplyForLevel}
              style={{ width: "150px", height: "36px" }}
              color="blue"
              className={"background-transaparent"}
              text={"Apply"}
              disabled={session.status == 4 ? true : disable}
            />
          </Grid.Column>
        </Grid.Row>
        </>) : (null)}
      

        {error && error.length > 0 ? (
          <Grid.Row>
            <Grid.Column width={12} tablet={16} computer={12} mobile={16}>
              <Popup
                flowing
                hoverable
                trigger={
                  <p className="email-validation">
                    Eligibility not met for the requested Level,{" "}
                    <a
                      href="/faqs"
                      target="_blank"
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                        color: "red",
                      }}
                    >
                      please refer to FAQs.
                    </a>
                  </p>
                }
              >
                <Popup.Header>Eligibility Details</Popup.Header>
                <Popup.Content>
                  <Grid>
                    <Grid.Row columns={2}></Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <p>Courses</p>
                      </Grid.Column>
                      <Grid.Column>
                        {eligibility.CourseSuccess ? (
                          <Icon color={"green"} name="check circle" />
                        ) : (
                          <Icon color={"red"} name="times circle" />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <p>Audits</p>
                      </Grid.Column>
                      <Grid.Column>
                        {eligibility.AuditSuccess ? (
                          <Icon color={"green"} name="check circle" />
                        ) : (
                          <Icon color={"red"} name="times circle" />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={2}>
                      <Grid.Column>
                        <p>Experience</p>
                      </Grid.Column>
                      <Grid.Column>
                        {eligibility.experianceSuccess ? (
                          <Icon color={"green"} name="check circle" />
                        ) : (
                          <Icon color={"red"} name="times circle" />
                        )}
                      </Grid.Column>
                    </Grid.Row>
                  </Grid>
                </Popup.Content>
              </Popup>
            </Grid.Column>
            <Grid.Column width={2} computer={2} tablet={1}></Grid.Column>
            <Grid.Column
              width={2}
              computer={2}
              tablet={16}
              mobile={16}
              className={"button-flex"}
            ></Grid.Column>
          </Grid.Row>
        ) : null}
      </Grid>
      <Modal
        open={open ? open : false}
        setOpen={setOpen}
        title={"Warning !"}
        modalDescription={true}
        descriptionHeader={descriptionHeader}
        descriptionBody={descriptionBody}
        imageUrl={waringImage}
        imageSize="small"
        modalAction={true}
        ActionButtons={() => (
          <ControllButtonReg
            NoClick={() => {
              setApplyLevel(auditor?.txtApplyLevel);
              setOpen(false);
            }}
            YesClick={OnYesClick}
          />
        )}
      />
      <Modals
        open={errorPop}
        basicModal={false}
        modalSize={"small"}
        imageUrl={waringImage}
        imageSize="small"
        title={
          "Please check below message(s) and rectify <a href=" +
          `accreditation?error=1&stage=${applyLevel}` +
          ' target="_blank" rel="noreferrer" >Accreditation page</a>'
        }
        setOpen={setErrorPop}
        modalDescription={false}
        ModalBody={() => <ErrorList Error={errorList} />}
        modalDimmer={"blurring"}
        modalAction={false}
      />
    </React.Fragment>
  );
};

export default ApplyForLevelReview;

interface ControllButtonProps {
  //eslint-disable-next-line
  NoClick: any;
  //eslint-disable-next-line
  YesClick: any;
  //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
  return (
    <React.Fragment>
      <OutLineButton
        id={"btnCancel"}
        style={{ width: "150px" }}
        onClick={() => {
          props.NoClick();
        }}
        color={"red"}
        text={"No"}
      />
      <DefaultButton
        id={"btnSave"}
        style={{ width: "150px" }}
        onClick={() => {
          props.YesClick();
        }}
        color={"blue"}
        text={"Yes"}
      />
    </React.Fragment>
  );
};

interface ErrorProps {
  Error: ValidationResponseProps[];
}

const ErrorList = ({ Error }: ErrorProps) => {
  console.log("error", Error);
  return (
    <Grid>
      {Error.map((r: ValidationResponseProps, index: number) => (
        <Grid.Row key={index} columns={3}>
          <Grid.Column width={1}></Grid.Column>
          <Grid.Column width={1}>{index + 1}</Grid.Column>
          <Grid.Column width={12}>{r.Msg}</Grid.Column>
        </Grid.Row>
      ))}
    </Grid>
  );
};
