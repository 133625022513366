import React, {useEffect} from "react";
import {Grid} from "semantic-ui-react";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import NameWithDropDown from "../../../framework/widgets/NameWithDropDown";
import Position from "../../../framework/types/Position";
import NameWithDefault from "../../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../../framework/widgets/NameWithText/NameWithEmail";
import NameWithPassword from "../../../framework/widgets/NameWithText/NameWithPassword";
import NameWithText from "../../../framework/widgets/NameWithText";
import DropDownOptions from "../../../framework/types/DropDownOptions";
import {PersonalDetailsValueProps} from "./DataModal";

interface AccreditationDetailsProps{
    auditor?:any,
}

const AccreditationDetails = (props:AccreditationDetailsProps) => {
    const [status, setStatus] = React.useState<DropDownOptions[]>([]);
    const [state, setState] = React.useState<PersonalDetailsValueProps>();
    useEffect(() => {
        //eslint-disable-next-line
        //debugger
        Promise.resolve(props.auditor).then((value) => {
            //console.log(value)
            setState(value.personalDetails)

        })
    }, [props.auditor])
    return(
        <React.Fragment>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <NameWithDefault labelText={'Auditor ID'} labelPosition={Position.Top}
                                         id={'txtAuditor'} fluid={true} type={'text'}
                                         value={state?.txtNewAuditorId}
                                         disabled={true}
                        />
                        <p style={{fontStyle: 'normal'}}>Eligibility Level criteria, <a href={'/faqs'}>please refer to FAQs.</a></p>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} >
                    <Grid.Column width={4} mobile={16} widescreen={4} tablet={4} computer={4}>
                        <NameWithDropDown  clearable={true} selection={true} labelText={'Auditor level'}
                                           labelPosition={Position.Top} id={'ddApplyLevel'}
                                           placeholder={'Auditor level'}
                                           options={[{key: 1, text: 'Auditor (Level 1)', value: '1'}, {
                                               key: 2,
                                               text: 'Senior Auditor (Level 2)',
                                               value: '2'
                                           }, {key: 3, text: 'Leader (Level 3)', value: '3'}]}
                                           value={state?.txtCurrentLevel}
                        />
                    </Grid.Column>
                    <Grid.Column width={6} mobile={16} widescreen={6} tablet={6} computer={6}>
                        <NameWithDropDown   selection={true} labelText={'Auditor status'}
                                           labelPosition={Position.Top} id={'ddApplyLevel'}
                                           placeholder={'Auditor status'}
                                           options={status}
                                           value={state?.AuditorsStateRes?.auditorStatusId?.toString()}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                        <NameWithDefault labelText={'Accreditation expiry date'} labelPosition={Position.Top}
                                         id={'txtAED'} fluid={true} type={'text'}
                                         placeholder={'Enter Accreditation expiry date'}
                                         value={state?.AuditorsStateRes?.certExpiryDate}
                                         disabled={true}
                        />
                    </Grid.Column>
                    <Grid.Column width={8} mobile={16} widescreen={8} tablet={8} computer={8}>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </React.Fragment>
    )

}

export default AccreditationDetails