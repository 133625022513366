import ApiCallAxio from "../../common/apiCallAxio";
import APICallMethods from "../../types/APICallMethods";
import UserTitles from "../../../models/apiResponce/Common/userTitles";
import {IFileTypes} from "./IFileResult";
import {v4 as uuidv4} from 'uuid';
import {getSessionCookie} from "../../common/SessionHandler";
import ApiCall from "../../common/apiCall";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;


const fetchFiles = async (auditorEmail:string,fileType:string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Files/AuditorFiles`; //complete url
    const filessArray: IFileTypes[] = []; //array to store the titles list [{"key": 1,"value": "text"}, etc]
    const Data = {
        email: auditorEmail,
        countryStateId: 1,
        fileAttachmentType: fileType
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:Data}); //call the api using ApiCall method
    const objfilessArray: IFileTypes[] = result.fileRess; //store the result in userTitles array

    return objfilessArray;//return the array
}

const fetchCanUserUploadFiles = async (auditorId:number) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Files/CanCurrentUserUploadFile`; //complete url
    const Data = {
        auditorId: auditorId,
        countryStateId: 1,
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:Data}); //call the api using ApiCall method
    const blCanUseUploadFiles: boolean = result.canCurrentUserUploadFile; //store the result in userTitles array

    return blCanUseUploadFiles;//return the array
}

const deleteFile = async (auditorId:number,fileType:string, fileID:string) => {
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Files/DeleteFile`; //complete url
    const Data = {
        fileIdInGuid: fileID,
        fileAttachmentType: fileType,
        auditorId: auditorId,
        countryStateId: 1,
    }
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data:Data}); //call the api using ApiCall method
    const blDeleteFiles: boolean = result.fileDeleted; //store the result in userTitles array

    return blDeleteFiles;//return the array
}

const uploadFiles = async (auditorEmail:string,fileType:string,file:any,mappingId?:number) => {
    const session = getSessionCookie();
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Files/UploadFile`; //complete url
    const unique_id = uuidv4();
    const Data = {
        Email:auditorEmail,
        AuditorId:parseInt(session.auditorId),
        MappingAuditorCourseId:mappingId ? mappingId : null,
        AuditorCountryStateId:1,
        FileIdInGuid:unique_id,
        FileAttachmentType:fileType
    }

    const headers = new Headers();

    //eslint-disable-next-line
    const formdata = new FormData();
    formdata.append("file",file.files[0])
    formdata.append('request',JSON.stringify(Data))
    const result = await ApiCall({url: `${url}`, method: APICallMethods.POST, data:formdata, headers:headers, credentials:'include'}); //call the api using ApiCall method
    const objfilessArray: IFileTypes[] = result.fileRess; //store the result in userTitles array

    return objfilessArray;//return the array
}

export {
    fetchFiles,
    fetchCanUserUploadFiles,
    uploadFiles,
    deleteFile
}