import React, {useEffect, useState} from 'react'
import {Grid, Icon, SemanticICONS} from "semantic-ui-react";
import FileUpload from "./index";
import DefaultButton from "../Buttons/DefaultButton";
import OutLineButton from "../Buttons/OutlIneButton";
import FileProps from "../../types/FileProps";
import {documentValidation, resumeValidation, sizeValidation} from "../../validators/fileValidator";
import {calculateStorage, FileUploadValidator} from "../../common/SupportMobules";
import IconCircleButton from "../Buttons/IconCircleButton";
import {deleteFile, fetchCanUserUploadFiles, fetchFiles, uploadFiles} from "./FileUploadController";
import IFileUploader from "../../types/IFileUploader";
import {IFileList} from "./IFileResult";
import moment from "moment";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {regular, solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const FileUploadPop = (props: IFileUploader) => {
    const [file, setFile] = useState<FileProps>({pictureAsFile: {} as File, picturePreview: ''})
    const [uploadFile, setUploadFile] = React.useState<any>();
    const [filesList, setFilesList] = React.useState<IFileList[]>([])
    const [canUseuplaod, setCanUserUpload] = React.useState(true)
    const [text, SetText] = useState('')

    const fileUpliading = () => {
        const uplaodResult = uploadFiles(props.auditorEmail, props.fileType, uploadFile, props.mappingId)
        Promise.resolve(uplaodResult).then((value) => {
            if (value.length > 0) {
                loadFile();
                setFile({pictureAsFile: {} as File, picturePreview: ''})
            }
        })
    }

    const fileUpliadingOnTime = (e:any) => {
        SetText('Please wait ...')
        const uplaodResult = uploadFiles(props.auditorEmail, props.fileType, e, props.mappingId)
        Promise.resolve(uplaodResult).then((value) => {
            if (value.length > 0) {
                loadFile();
                setFile({pictureAsFile: {} as File, picturePreview: ''})
            }
        })
    }

    useEffect(() => {
        loadFile()
        checkUserUplaodable()
    }, [])

    const checkUserUplaodable = () =>{
        if(!props.editable){
            const result = fetchCanUserUploadFiles(props.auditorId)
            Promise.resolve(result).then((value)=>{
                setCanUserUpload(value ? value : false)

            })
        }

    }

    const SetIcon = (fileType: string) => {
        let fileIcon: IconProp = 'paperclip'
        switch (fileType) {
            case 'rtf':
                fileIcon = 'file'
                break
            case 'jpg':
                fileIcon = 'file-image'
                break
            case 'pdf':
                fileIcon = 'file-pdf'
                break
            case 'doc':
                fileIcon = 'file-word'
                break
            case 'docx':
                fileIcon = 'file-word'
                break
            case 'jpeg':
                fileIcon = 'file-image'
                break
            case 'png':
                fileIcon = 'file-image'
                break
            case 'txt':
                fileIcon = 'file-text'
                break
            case 'gif':
                fileIcon = 'file-image'
                break
            default :
                fileIcon = 'file'
                break
        }
        return fileIcon
    }

    const loadFile = () => {
        const getfile = fetchFiles(props.auditorEmail, props.fileType)
        Promise.resolve(getfile).then((value) => {
            SetText('')
            if (value.length > 0) {
                //eslint-disable-next-line
                let ArrFiles: IFileList[] = [];
                value.map((r) => {
                    if (r.type == 'CERT') {
                        if (r.refId2 == props.mappingId) {
                            ArrFiles.push({
                                fileName: r.originalFilename,
                                url: r.fileNavigateUrl,
                                id: r.id,
                                createdDate: r.createdDate,
                                icon: SetIcon(r.physicalFileExt)
                            })
                        }
                    } else {
                        ArrFiles.push({
                            fileName: r.originalFilename,
                            url: r.fileNavigateUrl,
                            id: r.id,
                            createdDate: r.createdDate,
                            icon: SetIcon(r.physicalFileExt)
                        })
                    }
                })

                setFilesList(ArrFiles)
            }else{
                setFilesList([])
            }
        })
    }

    const deleteFiles = (fileId:string) => {
        const result = deleteFile(props.auditorId,props.fileType,fileId)
        Promise.resolve(result).then((value)=>{
            loadFile()

        })
    }

    return (
        <React.Fragment>
            <Grid>
                {canUseuplaod ? (
                    <>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <p>{props.message}</p>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row columns={2}>
                            <Grid.Column>
                                <FileUpload
                                    color={'blue'}
                                    onChange={(e: any) => {
                                        if (resumeValidation(e) && sizeValidation(FileUploadValidator(e).pictureAsFile.size,30)) {
                                            setFile(FileUploadValidator(e));
                                            setUploadFile(e.target)
                                            fileUpliadingOnTime(e.target)
                                        }
                                    }}
                                    disabled={false}
                                />
                            </Grid.Column>
                            <Grid.Column style={{display: 'flex', justifyContent: 'center'}} className={'button-flex'}>
                                {text}
                                {/*<OutLineButton id={'btnClick'} onClick={fileUpliading} color={'blue'}*/}
                                {/*               className={"background-transaparent"} text={'Upload'}*/}
                                {/*               disabled={file.pictureAsFile.size > 0 ? false : true}/>*/}
                            </Grid.Column>
                        </Grid.Row>
                        {file.pictureAsFile.size > 0 ? (
                            <Grid.Row columns={2}>
                                <Grid.Column>File name - {file.pictureAsFile.name} </Grid.Column>
                                <Grid.Column style={{display: 'flex', justifyContent: 'center'}}>File size
                                    - {calculateStorage(file.pictureAsFile.size)} </Grid.Column>
                            </Grid.Row>
                        ) : null}
                        <Grid.Row>
                            <Grid.Column>
                                <p>Up to 30MB for file types .pdf .png .jpg .jpeg .gif .doc .docx .rtf .txt</p>
                            </Grid.Column>
                        </Grid.Row>
                    </>) : null}
                <Grid.Row>
                    <Grid.Column>
                        <hr/>
                    </Grid.Column>
                </Grid.Row>
                {filesList.map((r, index) => (
                    <Grid.Row columns={3} key={index}>
                        <Grid.Column width={2} style={{display:'flex', justifyContent:'center'}}>
                            <FontAwesomeIcon icon={r.icon}  style={{fontSize:'1.5rem'}}/>
                            {/*<Icon className={r.icon} size={'big'}/>*/}
                        </Grid.Column>
                        <Grid.Column>
                            <a href={r.url}>{`${r.fileName} | ${moment(r.createdDate).format("DD/MM/YYYY hh:mm:ss A")}`}</a>
                        </Grid.Column>
                        <Grid.Column style={{display:'flex', justifyContent:'end', cursor:'pointer'}}>
                            {canUseuplaod ? (
                                    <FontAwesomeIcon icon={regular("trash-can")} id={'btnDelete'} onClick={() => {
                                        deleteFiles(r.id)
                                    }} title={'delete'} style={{fontSize:'1.5rem'}} />
                            //     <IconCircleButton id={'btnDelete'} onClick={() => {
                            //     deleteFiles(r.id)
                            // }} color={'red'} icon='delete'/>
                                ):null}
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        </React.Fragment>
    );
}

export default FileUploadPop