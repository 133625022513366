

//Import url from config file
import ApiCallAxio from "../../../framework/common/apiCallAxio";
import APICallMethods from "../../../framework/types/APICallMethods";
import UserTitles from "../../../models/apiResponce/Common/userTitles";
import {SearchReq} from "../../../models/apiRequest/Auditor/SearchReq";
import {SearchAuditsRes, SearchRes} from "../../../models/apiResponce/Auditor/SearchRes";
import {AdminNotificationCount, AdminNotificationCountAudits} from "../../../framework/types/AdminNotificationCount";

const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const SearchApiCallAdmin = async (state:number, pageNumber:number) =>{
    //eslint-disable-next-line
    let SearchRsult:SearchRes
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorsForAdmin?statue=${state}&pageNumber=${pageNumber}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET, }); //call the api using ApiCall method
    //eslint-disable-next-line
    SearchRsult = result; //store the result in userTitles array

    return SearchRsult
}

const SearchApiCallAdminAudits = async (state:number, pageNumber:number) =>{
    //eslint-disable-next-line
    let SearchRsult:SearchAuditsRes
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/auditgroup?StatusId=${state}&pageNumStartingZero=${pageNumber}`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET, }); //call the api using ApiCall method
    //eslint-disable-next-line
    SearchRsult = result; //store the result in userTitles array

    return SearchRsult
}

const fetchAdminNotification = async () =>{
    //eslint-disable-next-line
    let SearchRsult:AdminNotificationCount
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/AuditorProfile/AuditorsForAdmin`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST}); //call the api using ApiCall method
    //eslint-disable-next-line
    SearchRsult = result.auditorRess; //store the result in userTitles array

    return SearchRsult
}

const fetchAdminNotificationAudits = async () =>{
    //eslint-disable-next-line
    let SearchRsult:AdminNotificationCountAudits
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/auditgroup`; //complete url
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST}); //call the api using ApiCall method
    //eslint-disable-next-line
    SearchRsult = result; //store the result in userTitles array

    return SearchRsult
}


export {SearchApiCallAdmin, fetchAdminNotification, fetchAdminNotificationAudits, SearchApiCallAdminAudits}