import React, {useCallback, useEffect, useState} from 'react';
import {Routes, Route, Navigate, useNavigate} from "react-router-dom";

//Import UI Framework
import 'fomantic-ui-css/semantic.css'
//import './assets/css/semantic.css'

//Import Custom Styles
import './assets/scss/theme.scss'

//Import Routes
import {authRoutes, guestRoutes} from "./routes/RouteList";

//Import Google Recaptcha
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from 'react-google-recaptcha-v3';

//Import Session Cookie
import {getSessionCookie} from "./framework/common/SessionHandler";
import {SessionContext} from './models/context/SessionContext';

import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'


library.add(fas, far)



function App() {
    const navigate = useNavigate();
    const [session, setSession] = useState(getSessionCookie());
    const config = window.config;


    useEffect(() => {
        if (process.env.REACT_APP_MAINTENANCE == '1' || config.MaintenanceMode == true) {
            navigate('/maintenance');
            //window.location.href = '/maintenance'
        }
        setSession(getSessionCookie());
    }, [config.MaintenanceMode]);


    return (
            <SessionContext.Provider value={{session}}>
                <>
                    <React.Fragment>
                        {/*<RouteChangeTracker />*/}
                        <Routes>
                            {authRoutes.map((route: { url: string, widget: any }, index: number) => (
                                <Route key={index} path={route.url}
                                       element={session ? <route.widget/> :
                                           <Navigate key={index} to={'/'}/>}/>
                            ))}
                            {guestRoutes.map((route: { url: string, widget: any }, index: number) => (
                                <Route key={index} path={route.url} element={<route.widget/>}/>
                            ))}
                        </Routes>
                    </React.Fragment>
                </>
            </SessionContext.Provider>
    );
}

//{/*<Route key={index} path={route.url} element={process.env.REACT_APP_MAINTENANCE == '1' ? <Navigate key={index} to={'/maintenance'}/> : <route.widget/>}/>*/}
export default App;
