import React, {useEffect, useState} from "react";
import InnerTitleBar from "../../../framework/layouts/InnerTitleBar";
import {Container, Grid, Input} from "semantic-ui-react";
import AdminNotification from "./AdminNotification";
import AdminMessage from "./AdminMessage";
import GeneralMsg from "./GeneralMessage";
import {userRoleSelector} from "../../../common/roleSelector";
import {fetchAdminNotification, fetchAdminNotificationAudits} from "../common/SearchController";
import {AdminNotificationCount, AdminNotificationCountAudits} from "../../../framework/types/AdminNotificationCount";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import NameWithRadioButton from "../../../framework/widgets/NameWithCheckBox/NameWithRadioButton";
import {SearchApiCall} from "../../search/SearchController";

const WelcomePage = ({session}: any) => {

    const [AuditorCounts, SetAuditorCounts] = useState<AdminNotificationCount>({
        newApplicationsReadyForAdmin:0,
        activeAuditors:0,
        activeAuditorsInLevelReview:0,
        activeAuditorsWithAllUnverifiedCourses:0,
        activeAuditorsWithUnconfirmedAudits:0,
        auditorsWithUnverifiedAudits:0,
        auditorUnreadComments:0,
        newApplicationsinAssessment:0,
        newApplicationsInReview:0,
        newApplicationsWithIncompleteReg:0,
    } as AdminNotificationCount)
    const [AuditsCounts, SetAuditsCounts] = useState<AdminNotificationCountAudits>({
        pendingRoadSafetyAuditsinreviewprocess:0,
        pendingRoadSafetyAuditstobeverified:0,
        verifiedAuditswithunconfirmedAuditors:0,
        verifiedRoadSafetyAudits:0,
        notrecognisedRoadSafetyAudits:0
    })
    useEffect(()=>{
        const SearchApi = fetchAdminNotification();
        Promise.resolve(SearchApi).then((value)=>{
            if(value){
                SetAuditorCounts(value)
            }
        })
        const SearchApiAudits = fetchAdminNotificationAudits();
        Promise.resolve(SearchApiAudits).then((value)=>{
            if(value){
                SetAuditsCounts(value)
            }
        })
    },[])

    return (
        <Container fluid={true} style={{/*height: '750vh',*/ marginTop: '14px', marginBottom: '20px'}}>
            <InnerTitleBar
                title={"Welcome"}
                usreName={session.userDisplayName != null ? session.userDisplayName : "  "}
                userRole={userRoleSelector(session.userRoleId.toString())}
                lastLogin={session.lastLogin.toString()}
                status={userRoleSelector(session.userRoleId.toString())}
            />
            <Grid id={'thank-page'}  style={{marginBottom: '40px', marginTop: '-50px'}}>
                <Grid.Row>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>

                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <h1 style={{display: 'inline-flex', color: "#1559A3"}}>Notifications</h1>
                                </Grid.Column>
                                <Grid.Column>
                                    <SimpleSearch/>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                       <AdminNotification
                           AuditorCounts={AuditorCounts}
                           AuditCounts={AuditsCounts}
                       />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <AdminMessage
                            AuditorCounts={AuditorCounts}
                        />
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>

                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <hr/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={3}>
                    <Grid.Column width={2}>

                    </Grid.Column>
                    <Grid.Column width={12}>
                        <GeneralMsg/>
                    </Grid.Column>
                    <Grid.Column width={2}>

                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </Container>
    );
}

export default WelcomePage;


const SimpleSearch = () => {
    const [searchOption, setSearchOption] = useState('Auditors')
    const [txtName, setTxtName] = useState('')

    return(
        <Grid>
            <Grid.Row columns={3}>
                <Grid.Column width={4}></Grid.Column>
                <Grid.Column width={4}>
                    <NameWithRadioButton
                        id={'rbtAuditors'}
                        label={`Auditors`}
                        name={'Search'}
                        value={searchOption === 'Auditors'}
                        onChange={() => {
                            setSearchOption('Auditors')
                        }}
                    />
                    <NameWithRadioButton
                        id={'rbtAudits'}
                        label={`Audits`}
                        name={'Search'}
                        value={searchOption === 'Audits'}
                        onChange={() => {
                            setSearchOption('Audits')
                        }}
                    />
                </Grid.Column>
                <Grid.Column width={6}>
                    <div className="ui action input button-flex">
                        <input type="text" placeholder={`${searchOption} Search...`} value={txtName} onChange={(e)=>{setTxtName(e.target.value)}}/>
                        <OutLineButton id={'cmbApplyLevelReview'} onClick={ ()=>window.location.href = `/search/auditors?status=${searchOption == 'Auditors' ? 999 : 99}&name=${txtName}`}
                                       color='blue' className={"background-transaparent"} text={'Search'}
                        />
                    </div>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    )
}