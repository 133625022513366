/*
 *################################################################################
 *Module Name                   	: RouteList.ts                				 #
 *Purpose of the Module         	: To Show Route the request        			 #
 *Description of the Module    	    : This module route client request to        #
 *    		                           auth or guest according to url            #
 *Initial Author               	    : T A C Priyashad                            #
 *Date                          	: 11/04/2022                   		         #
 *Copyright © 2022 ITworx Consulting.                                   		 #
 *################################################################################
 */
//Import AuthPages
import Profile from '../pages/auditor/profile';
import AuditorWelcome from '../pages/auditor/welcome';

//Import Admin AuthPages
import AdminWelcome from "../pages/admin/welcome";
import SearchTable from "../pages/admin/common/SearchTable"

//Import GuestPages
import Home from "../pages/home";
import Sample from "../pages/sample";
import Maintenance from "../pages/maintenance";
import Aboutus from "../pages/aboutus";
import Contactus from "../pages/contactus";
import Registration from "../pages/auditor/registration";
import RegiAuthThanks from "../pages/StaticPages/RegiAuthThanks"
import Faqs from '../pages/faqs';
import Faqspop from '../pages/faqs_pop';
import NotFound from "../pages/Warring/404";

//Component
import SampleButtons from "../pages/sample/SampleButtons";
import SampleTextBoxes from "../pages/sample/SampleTextBoxes";
import SampleOptions from "../pages/sample/SampleOptions";
import SampleDropDown from "../pages/sample/SampleDropDown";
import SampleModals from "../pages/sample/SampleModals";
import SampleTosat from "../pages/sample/SampleToast";

//Widgets
import SampleNameWithText from "../pages/sample/SampleNameWithText";
import SampleNameWithDropDown from "../pages/sample/SampleNameWithDropDown";

//Modules
import SampleSupportModule from "../pages/sample/SampleSupportModules";
import Searh from "../pages/search";
import Audits from "../models/apiResponce/Audits/Audits";
import PgsAudits from "../pages/audits";
import AuditorDetails from "../pages/search/AuditorDetails";
import Discaimer from "../pages/StaticPages/Disclaimer";
import SiteMap from "../pages/StaticPages/SiteMap";
import Training from '../pages/training';



//Define authRoutes which is render when user have permission to view these components.
const authRoutes : {url:string, widget:any}[] = [
    {url:'/profile', widget:Profile},
    {url:'/accreditation', widget:Profile},
    {url:'/welcome', widget:AuditorWelcome},
    {url:'/audits', widget:PgsAudits},
    {url:'/dashboard', widget:AdminWelcome},
    {url:'/search/auditors', widget:SearchTable},
    // this route should be at the end of all other routes
];

//Define guestRoutes which is render without checking user permissions.
interface guestRouteProps {
    url:string,
    widget:any
}
const guestRoutes : {url:string, widget:any}[] = [
    {url:"/home", widget:Home},
    {url:"/samples", widget:Sample},
    {url:"/RegiAuthThanks", widget:RegiAuthThanks},
    {url:"/terms", widget:Discaimer},
    {url:"/sitemap", widget:SiteMap},
    //about us, contact us and faqs edited by ShaneW
    {url:"/aboutus", widget:Aboutus},
    {url:"/contactus", widget:Contactus},
    {url:"/faqs", widget:Faqs},
    {url:"/faqspop", widget:Faqspop},
    {url:"/sampleButtons" ,widget:SampleButtons},
    {url:"/sampleTextBoxes" ,widget:SampleTextBoxes},
    {url:"/sampleOptions" ,widget:SampleOptions},
    {url:"/sampleDropDowns" ,widget:SampleDropDown},
    {url:"/sampleModals" ,widget:SampleModals},
    {url:"/sampleToasts" ,widget:SampleTosat},
    {url:"/sampleNameWithText" ,widget:SampleNameWithText},
    {url:"/sampleNameWithDropDown" ,widget:SampleNameWithDropDown},
    {url:"/sampleSupportModule" ,widget:SampleSupportModule},
    {url:"/maintenance" ,widget:Maintenance},
    {url:"/registration" ,widget:Registration},
    {url:"/training" ,widget:Training},
    {url:"/search" ,widget:Searh},
    {url:"/auditor", widget:AuditorDetails},
    {url:"/", widget:Home},
    {url:"/404", widget:NotFound}
];

//Export both authRoutes and guestRoutes functions, Which needs to access it from outside this file (Make it accessible to any ts file)
export {authRoutes,guestRoutes}