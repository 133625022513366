import React, {useEffect, useState} from 'react'
import {Grid} from "semantic-ui-react";
import TableWithBackground from "../../../framework/widgets/TableWithBackgrounds";
import RSATable from "../../../framework/widgets/TableWithBackgrounds/RSATable";
import AddNewCourse from "./AddNewCourse";
import TableHeader from "../../../framework/types/TableHeader";
import TableRow from "../../../framework/types/TableRow";
import tags from "../../../models/common/tag";
import {RoadSafetyCoursesProps} from "./DataModal";
import {fetchAuditor, RemoveMefromAudit, RemoveMefromCourse, UpdateMsgFromAdmin} from "./ProfileController";
import {getSessionCookie} from "../../../framework/common/SessionHandler";
import InfoToggle from "../../../framework/widgets/InfoToggle";
import MsgFromAdmin from "../../../models/apiResponce/Auditor/MsgFromAdmin";
import {PaginationProps} from "antd";
import {fetchFiles} from "../../../framework/components/FileUpload/FileUploadController";
import Modal from "../../../framework/components/Modals";
import FileUploadPop from "../../../framework/components/FileUpload/FileUploadPop";
import waringImage from "../../../assets/images/icons/warning.png";
import OutLineButton from "../../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../../framework/components/Buttons/DefaultButton";

interface Props {
    provider: any;
    course: any;
    auditor: any;
    setAuditor: any;
    focusMode: any;
    setFocusMode: any;
}

const RoadSafetyCourses = (props: Props) => {
    const [open, setOpen] = useState(false);
    const [open1, setOpen1] = useState(false);
    const [open2, setOpen2] = useState(false);
    const [delCourseId, setDelCourseId] = useState(0);
    const [courseIds, setCourseId] = useState(0);
    const [courseMIds, setCourseMId] = useState(0);
    const [isEdit, setIsEdit] = React.useState<boolean>(true);
    const [isAddnew, setIsAddnew] = React.useState<boolean>(true);
    const [auditorId, setAuditorId] = useState<any>();
    const [providerEmail, setProviderEmail] = useState("");
    const [certification, setCertification] = useState("");
    const [rsc, setRSC] = useState<MsgFromAdmin>({} as MsgFromAdmin);
    const [isConfirmRSC, setIsConfirmRSC] = useState(false);
    const [isConfirmRSC2, setIsConfirmRSC2] = useState(false);
    const [current, setCurrent] = useState(1);
    const session = getSessionCookie();
    const [courseMid, setCourseMid] = useState(0)
    const encid = new URLSearchParams(location.search).get('get')

    const rsaCourseHead: TableHeader[] = [
        {
            text: "Date"
        },
        {
            text: "Course Provider"
        },
        {
            text: "Course Name"
        },
        {
            text: "Classification"
        },
        {
            text: "Attachment"
        },
        {
            text: "Verified"
        },
        {
            text: " "
        }
    ];

    useEffect(() => {
        callApi(false, true);
        if(props.auditor.RSC){
            setIsConfirmRSC(props.auditor.RSC.isConfirmedByAuditor ? props.auditor.RSC.isConfirmedByAuditor : false)
            setIsConfirmRSC2(props.auditor.RSC.isConfirmedByAuditor2 ? props.auditor.RSC.isConfirmedByAuditor2 : false)
        }

    }, [props.auditor])

    const onChange: PaginationProps['onChange'] = (page) => {
        console.log(page);
        setCurrent(page);
    };

    const callApi = (refresh: boolean, show: boolean) => {
        if (refresh) {
            setIsAddnew(true)
            const reload = fetchAuditor(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? parseInt(atob(encid)) : session.auditorId, 0)
            Promise.resolve(reload).then((value: any) => {
                SetValues(value);
                props.setAuditor(value)
                if (show) {
                    setOpen(!open);
                }

            })
        } else {
            Promise.resolve(props.auditor).then((value) => {
                SetValues(value);
            })
        }

    }

    useEffect(() => {
        callApi(false,false)
    },[isEdit])


    const SetValues = (value: any) => {
        const TableRows: object[] = [];
        const courses = value.roadSafetyCourses;
        setAuditorId(value.personalDetails.txtAuditorId);
        //eslint-disable-next-line
        //debugger
        courses.map((course: RoadSafetyCoursesProps) => {
            TableRows.push([{
                tag: tags.text,
                text: course.courseDate,
                icon: "",
                onclick: null
            },
                {
                    tag: tags.text,
                    text: course.courseProvider,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: course.courseName,
                    icon: "",
                    onclick: null
                },
                {
                    tag: tags.text,
                    text: course.courseTentativeClassification ? course.courseTentativeClassification : "Auditor",
                    icon: "",
                    onclick: null
                },
                {
                    tag: "icon-fa",
                    text: "center",
                    icon: course.iconName,
                    onclick: course.iconName == 'file' || isEdit == true ? ()=>{ setOpen1(true) ;setCourseMId(course.id ? course.id : 0)}: null
                },
                encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ?
                    {
                        tag: "checkbox",
                        text: course.isVerified,
                        icon: course.isVerified == true ? "check" : "minus",
                        onclick: null
                    } : {
                        tag: "icon-fa",
                        text: "tfnsw",
                        icon: course.isVerified == true ? "check" : "minus",
                        onclick: null
                    },
                {
                    tag: "icon-fa",
                    text: "View Course",
                    icon: encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? "view" :"view",
                    onclick: (e: any) => {
                        setIsAddnew(course.courseId ? false : true)
                        setCourseId(course.courseId ? course.courseId : 0);
                        setProviderEmail(course.providerEmail ? course.providerEmail : "");
                        setCourseMId(course.id ? course.id : 0)
                        setCertification(course.certificateNumber ? course.certificateNumber : "");
                        setTimeout((e) => {
                            toggleModel(e)
                        }, 10);

                    }
                },
                {
                    tag: "icon-fa",
                    text: "Remove",
                    icon: "delete",
                    onclick: () => {
                        setDelCourseId(course.id ? course.id : 0)
                        setOpen2(true)
                        //
                    }
                }])
        })
        setIsEdit(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : value.RSC.isActivated ? value.RSC.isActivated : value.Editable)
        setRSC(value.RSC);
        setRSACourseDet(TableRows);
    }

    const DeleteCourse = (id: number) => {
        const result = RemoveMefromCourse(id);
        Promise.resolve(result).then((value) => {
            //props.setAuditor(fetchAuditor(session.auditorId))
            callApi(true, false);
            setOpen2(false)
        });
    }


    const toggleModel = (e: any) => {
        //eslint-disable-next-line
        //debugger;
        if (open && e) {
            callApi(true, true);
        } else {
            props.setFocusMode("course")
            setOpen(true)

        }

    }

    const toggleUpload = () => {
        const reload = fetchAuditor(encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? parseInt(atob(encid)) : session.auditorId, 0)
        Promise.resolve(reload).then((value) => {
            SetValues(value);
            setOpen1(false)
        })

    }
    const onEditClick = (courseId: number, e: any) => {
        setCourseId(courseId);
        toggleModel(e);
    }

    const onRSCChange = async (id: number, e: any, data: any, sectionName:string) => {
        setIsConfirmRSC(!isConfirmRSC);
        await UpdateMsgFromAdmin(id, !isConfirmRSC, sectionName)
    }

    const onRSCChange2 = async (id: number, e: any, data: any, sectionName:string) => {
        setIsConfirmRSC2(!isConfirmRSC2);
        await UpdateMsgFromAdmin(id, !isConfirmRSC2, sectionName)
    }

    const [rsaCourseDet, setRSACourseDet] = useState<any>([
        //rsaCourseDetRow,
    ])
    return (
        <React.Fragment>
            <div className="ui center aligned icon header red-title" id={'courses'}>
                <h1 className="ui center aligned icon header red-title" >Road Safety Courses</h1>
            </div>
            <Grid>
                <Grid.Row>
                    <Grid.Column width={16}>
                        {rsc.isActivated ? (<InfoToggle
                            type={rsc.Type}
                            msg={rsc.msgFromAdmin}
                            update={rsc.update}
                            msg2={rsc.msgFromAdmin2 ? rsc.msgFromAdmin2 : ''}
                            update2={rsc.update2}
                            id={'chkAdminConformRSC'}
                            id2={'chkAdminConformRSC2'}
                            value={isConfirmRSC}
                            value2={isConfirmRSC2}
                            onChange={(e: any, data: any) => {
                                onRSCChange(rsc.id, e, data, rsc.sectionName)
                            }}
                            onChange2={(e: any, data: any) => {
                                onRSCChange2(rsc.id2 ? rsc.id2 : 0, e, data, rsc.sectionName)
                            }}
                        />) : null}
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column>
                        <div>
                            <h3 style={{display: 'inline-flex', color: "#1559A3"}}><b>Recognised Training Courses</b></h3>
                            <p>Enter details of your road safety training course (note that you must provide a copy of your training
                                certificate)</p>
                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={16}>
                        <TableWithBackground
                            title={"Courses Summary"}
                            subTitle={"Remember to attach your certificate for each of these road safety training courses you have added"}
                            Component={() => (
                                <div style={{minWidth: '10px', overflow: 'auto'}}>
                                    <RSATable
                                        head={rsaCourseHead}
                                        body={rsaCourseDet}
                                        current={current}
                                        pageCount={1}
                                        onChange={onChange}
                                    />
                                </div>
                            )
                            }
                            modalTitle={isAddnew ? 'Add Course Details' : 'View Course Details'}
                            modalBody={() => (<AddNewCourse
                                provider={props.provider}
                                course={props.course}
                                courseId={courseIds}
                                courseMId={courseMIds}
                                toggleModel={toggleModel}
                                setRSACourseDet={setRSACourseDet}
                                rsaCourseDet={rsaCourseDet}
                                setCourseId={setCourseId}
                                onEditClick={onEditClick}
                                isEdit={isEdit}
                                providerEmail={providerEmail}
                                certificateNumber={certification}
                                auditorId={auditorId}
                                setAuditor={props.setAuditor}
                            />)}
                            buttonText={"Add Course"}
                            modalAction={false}
                            open={open}
                            setOpen={setOpen}
                            disabled={!isEdit}
                            toggleModel={(e: any) => {
                                setIsAddnew(true)
                                toggleModel(e);
                                setCourseId(0)
                                setCourseMId(0)
                            }}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Modal open={open1 ? open1 : false} modalAction={false} setOpen={toggleUpload} title={'Course Certificates'}
                       ModalBody={() => (
                           <FileUploadPop
                               fileType={'CERT'}
                               auditorEmail={session.userName}
                               mappingId={courseMIds}
                               message={'Please note you can only have one course certificate for each course and uploading a new file will replace the existing file'}
                               auditorId={auditorId != null ? auditorId : session.auditorId}
                               editable={encid != null && (session.userRoleId == 1 || session.userRoleId == 4) ? true : rsc.isActivated}
                           />)}/>
                <Modal
                    open={open2 ? open2 : false}
                    setOpen={setOpen2}
                    title={"Warning !"}
                    modalDescription={true}
                    descriptionHeader={'Are you sure you want to delete this course?'}
                    descriptionBody={'This action cannot be undone.'}
                    imageUrl={waringImage}
                    imageSize='small'
                    modalAction={true}
                    ActionButtons={() => (
                        <ControllButtonReg
                            NoClick={() => {
                                setOpen2(false)
                                setDelCourseId(0)
                            }}
                            YesClick={()=>DeleteCourse(delCourseId)}
                        />
                    )}
                />
            </Grid>
            <br/>
            <hr/>
        </React.Fragment>
    )
}

export default RoadSafetyCourses


interface ControllButtonProps {
    //eslint-disable-next-line
    NoClick: any;
    //eslint-disable-next-line
    YesClick: any;
    //eslint-disable-next-line
}

const ControllButtonReg = (props: ControllButtonProps) => {
    return (
        <React.Fragment>
            <OutLineButton id={'btnCancel'} style={{width: '150px'}} onClick={() => {
                props.NoClick()
            }} color={'red'} text={'No'}/>
            <DefaultButton id={'btnSave'} style={{width: '150px'}} onClick={() => {
                props.YesClick()
            }} color={'blue'}
                           text={'Yes'}/>
        </React.Fragment>
    )
}
