import React from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import {Container, Grid, Image} from "semantic-ui-react";
import InternalBG from "../../assets/images/backgrounds/internal-pages.jpg";
import NameWithDropDown from "../../framework/widgets/NameWithDropDown";
import Position from "../../framework/types/Position";
import NameWithDefault from "../../framework/widgets/NameWithText/NameWithDefault";
import NameWithEmail from "../../framework/widgets/NameWithText/NameWithEmail";
import NameWithCheckBox from "../../framework/widgets/NameWithCheckBox/NameWithCheckBox";
import OutLineButton from "../../framework/components/Buttons/OutlIneButton";
import DefaultButton from "../../framework/components/Buttons/DefaultButton";
import InnerTitleBar from "../../framework/layouts/InnerTitleBar";

const Discaimer =()=>{
    return (
        <>
            <Header>
                <InnerTitleBar
                    title={'Disclaimer'}
                    usreName={''}
                    userRole={""}
                    lastLogin={''}
                    status={''}
                />
                <Container className='reg-thank' fluid={true} style={{marginBottom: '50px'}}>
                    <Grid>
                        <Grid.Row columns={3}>
                            <Grid.Column width={2}>

                            </Grid.Column>
                            <Grid.Column width={12}>
                                <h1></h1>
                                <p style={{fontSize: '18px'}}>The information and data contained in the audits and auditor profiles are provided by the Register of Road Safety Auditors for general information purposes only. While the Register of Road Safety Auditors takes care in the compilation and provision of the information and data, it does not assume or accept any liability for the accuracy, quality, suitability and currency of the information or data, or for any reliance on the information or data. The Register of Road Safety Auditors recommends that users exercise their own care, skill and diligence with respect to the use and interpretation of the information and data.</p>
                                <p style={{fontSize: '18px'}}>The material at this site may include comments by third parties which do not necessarily reflect the views of the Register of Road Safety Auditors or indicate its commitment to a particular course of action.</p>
                                <p style={{fontSize: '18px'}}>Links to other web sites are provided for convenience and are not an endorsement of material at those sites or any associated organisation, product or service. These external information sources are outside our control. It is the responsibility of users to make their own decisions about the accuracy, currency, reliability and correctness of the information at those sites.</p>
                                <p style={{fontSize: '18px'}}>By accessing information at or through this site each user waives and releases the Register of Road Safety Auditors to the full extent permitted by law from any and all claims relating to the usage of the material made available through the web site. In no event shall the Register of Road Safety Auditors be liable for any incident or consequential damages resulting from use of the material.</p>
                            </Grid.Column>
                            <Grid.Column width={2}>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Container>
            </Header>
            <Footer/>

        </>
    )
}

export default Discaimer