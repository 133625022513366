import ErrorToast from "../components/Toast/ErrorToast";
import notify from "../components/Toast";
import {toast} from "react-toastify";
import POSITION = toast.POSITION;

const documentValidation = (e:any) => {

    const filePath = e.target.files[0].name;
    // Allowing file type
    const allowedExtensions = /(\.doc|\.docx|\.odt|\.pdf|\.tex|\.txt|\.rtf|\.wps|\.wks|\.wpd)$/i;

    if (!allowedExtensions.exec(filePath)) {
        notify({
            type: 'error',
            color: 'colored',
            position: POSITION.TOP_RIGHT,
            message: "Invalid file type !",
            autoClose: 2000,
        })
        return false;
    }

    return true;
}

const imageValidation = (e:any) => {
    const filePath = e.target.files[0].name;

    // Allowing file type
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp|\.tiff|\.bmp|\.ai|\.gif|\.psd|\.pdf|\.raw)$/i;

    if (!allowedExtensions.exec(filePath)) {
        notify({
            type: 'error',
            color: 'colored',
            position: POSITION.TOP_RIGHT,
            message: "Invalid file type !",
            autoClose: 2000,
        })
        return false;
    }
    return true;
}


const resumeValidation = (e:any) => {
    const filePath = e.target.files[0].name;

    // Allowing file type
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.webp|\.bmp|\.ai|\.psd|\.pdf|\.doc|\.docx|\.odt|\.pdf|\.wps)$/i;

    if (!allowedExtensions.exec(filePath)) {
        notify({
            type: 'error',
            color: 'colored',
            position: POSITION.TOP_RIGHT,
            message: "Invalid file type !",
            autoClose: 2000,
        })
        return false;
    }

    return true;
}

const sizeValidation = (actualSize:number, allowSize:number) => {
    const sizeInMB = actualSize / 1048576
    if( sizeInMB >  allowSize ){
        notify({
            type: 'error',
            color: 'colored',
            position: POSITION.TOP_RIGHT,
            message: `Exceeding Allow file type !`,
            autoClose: 2000,
        })
        return false;
    }else{
        return true;
    }
}

export { documentValidation,
        imageValidation,
        resumeValidation,
        sizeValidation};