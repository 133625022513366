import { getSessionCookie } from "../../framework/common/SessionHandler";
import ApiCallAxio from "../../framework/common/apiCallAxio";
import APICallMethods from "../../framework/types/APICallMethods";
import { AuditSearchReq } from "../../models/apiRequest/Audit/AuditSearchReq";
import Audits from "../../models/apiResponce/Audits/Audits";
import moment from "moment";
import { fetchSingleAudit } from "../auditor/profile/ProfileController";

//Import url from config file
const baseUrl = process.env.REACT_APP_API_BASEURL;
const baseUrl2 = process.env.REACT_APP_API_BASEURL2;
const sublink = process.env.REACT_APP_API_SUBLINK;
const version = process.env.REACT_APP_API_VERSION;

const fetchAudits = async (props:AuditSearchReq) => {
    const session = getSessionCookie();
    //const url = `${baseUrl}/${sublink}/${version}/Audits/fetch/${signOffDate}/${stageID}/${LocationStateID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAuditsWithPagination`; //complete url
    const data = JSON.stringify({
        countryStateId: props.LocationStateID ? props.LocationStateID : 0,
        auditFinalSignoffDate: props.signOffDate ? props.signOffDate == "Invalid date" ? null : props.signOffDate : null,
        auditFinalSignoffDate2: props.signOffDate2 ? props.signOffDate2 == "Invalid date" ? null : props.signOffDate2 : null,
        auditId: props.auditId ? props.auditId : 0,
        stageId: props.stageID ? props.stageID : 0,
        location: props.auditLocation ? props.auditLocation : null,
        title: props.auditTitle ? props.auditTitle : null,
        company: props.clientCompany ? props.clientCompany : null,
        pageSize: props.pageSize ? props.pageSize : 10,
        pageNumStartingZero: props.pageNumStartingZero ? props.pageNumStartingZero : 0,
        orderByFieldsIncAscOrDesc: props.orderByFieldsIncAscOrDesc ? props.orderByFieldsIncAscOrDesc : "",
    })
    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const auditorAvlsArray: number[] = [];
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess.lstAuditRes; //store the result in userTitles array
    console.log("fetchAudits Result: ",result);
    // const objTotalItem: number = result.auditRess.totalItemsCount; //store the result in userTitles array
    // const objTotalPage: number = result.auditRess.totalPages;
    // const objCurrentPage: number = result.auditRess.currentPageZeroBased;
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    for(let i = 0; i < objAuditStages.length; i++){
        if(await checkAuditorAudit(objAuditStages[i].auditId, session.auditorId)){
            auditorAvlsArray.push(objAuditStages[i].auditId)
        }
    }
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes",
            finalSignoffDate:moment(item.auditFinalSignoffDate).format("DD-MM-YYYY"),
            isAuditorAdded: auditorAvlsArray.includes(item.auditId)
        })
    })
    return auditsArray;//return the array
}
const fetchAuditsWithPaginations = async (props:AuditSearchReq) => {
    const session = getSessionCookie();
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAuditsWithPagination`; //complete url
    const data = JSON.stringify({
        countryStateId: props.LocationStateID ? props.LocationStateID : 0,
        auditFinalSignoffDate: props.signOffDate ? props.signOffDate == "Invalid date" ? null : props.signOffDate : null,
        auditFinalSignoffDate2: props.signOffDate2 ? props.signOffDate2 == "Invalid date" ? null : props.signOffDate2 : null,
        auditId: props.auditId ? props.auditId : 0,
        stageId: props.stageID ? props.stageID : 0,
        location: props.auditLocation ? props.auditLocation : null,
        title: props.auditTitle ? props.auditTitle : null,
        company: props.clientCompany ? props.clientCompany : null,
        pageSize: props.pageSize ? props.pageSize : 10,
        pageNumStartingZero: props.pageNumStartingZero ? props.pageNumStartingZero : 0,
        orderByFieldsIncAscOrDesc: props.orderByFieldsIncAscOrDesc ? props.orderByFieldsIncAscOrDesc : "",
    })
    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const auditorAvlsArray: number[] = [];
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.POST, data: data}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess.lstAuditRes; //store the result in userTitles array
    console.log("fetchAuditsWithPaginations Result: ",result);
    const objTotalItem: number = result.auditRess.totalItemsCount; 
    const objTotalPage: number = result.auditRess.totalPages;
    const objCurrentPage: number = result.auditRess.currentPageZeroBased;
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    for(let i = 0; i < objAuditStages.length; i++){
        if(await checkAuditorAudit(objAuditStages[i].auditId, session.auditorId)){
            auditorAvlsArray.push(objAuditStages[i].auditId)
        }
    }
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes",
            finalSignoffDate:moment(item.auditFinalSignoffDate).format("DD-MM-YYYY"),
            isAuditorAdded: auditorAvlsArray.includes(item.auditId)
        })
    })
    return {auditsArray, objTotalItem, objTotalPage, objCurrentPage};
}
const fetchAllActiveAudits = async (type:string, pageNumber:number) => {
    //const url = `${baseUrl}/${sublink}/${version}/Audits/fetch/${signOffDate}/${stageID}/${LocationStateID}`; //complete url
    const url = `${baseUrl2 ? baseUrl2 : baseUrl}/${sublink}/${version}/Audits/GetAllAudits?type=${type}&currentPage=${pageNumber}`; //complete url

    const auditsArray: object[] = []; //array to store the course providers list [{"key": 1,"value": "text"}, etc]
    const result = await ApiCallAxio({url: `${url}`, method: APICallMethods.GET}); //call the api using ApiCall method
    const objAuditStages: Audits[] = result.auditRess.lstAuditRes; //store the result in userTitles array
    const objTotalItem: number = result.auditRess.totalItemsCount; //store the result in userTitles array
    const objTotalPage: number = result.auditRess.totalPages;
    const objCurrentPage: number = result.auditRess.currentPageZeroBased;
    console.log("fetchAllActiveAudits Result: ",result );
    //loop through the result and push the titleId and titleName to the array according to dropdown option format
    objAuditStages.map((item: Audits) => {
        auditsArray.push({
            auditID: item.auditId.toString(),
            name: item.auditTitle,
            location: item.auditLocationDescription,
            tfnsw: item.isTfNswProject ? "Yes" : "No",
            isPubliclyVisible: "Yes",
            finalSignoffDate:moment(item.auditFinalSignoffDate).format("DD-MM-YYYY")
        })
    })
    return {auditsArray,objTotalItem,objTotalPage,objCurrentPage};//return the array
}

export {fetchAudits,fetchAllActiveAudits, fetchAuditsWithPaginations}; //export the function

const checkAuditorAudit = async (auditId:number, auditorId:number) => {
    const audit = await fetchSingleAudit(auditId)
    let isAuditorAdded = false;
   if(audit?.lstAuditParticipantsReq?.length != undefined){
        for(let i = 0; i < audit?.lstAuditParticipantsReq?.length; i++){
            if(audit?.lstAuditParticipantsReq[i].auditorId == auditorId){
                isAuditorAdded = true
            }
        }
   }

   return isAuditorAdded
}
