import React, {useEffect, useState} from "react";
import {Grid} from "semantic-ui-react";
import NotificationMenu from "../../../framework/widgets/MenuArray/NitificationMenu";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {solid} from "@fortawesome/fontawesome-svg-core/import.macro";
import {AdminNotificationCount, AdminNotificationCountAudits} from "../../../framework/types/AdminNotificationCount";

interface AdminNotificationProps{
    AuditorCounts:AdminNotificationCount
    AuditCounts:AdminNotificationCountAudits
}
const AdminNotification = (props:AdminNotificationProps) => {
    const [Auditors, setAuditors] = useState([
        {
            text:"New Applications ready for Admin",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=200'
        },
        {
            text:"New Applications not meeting minimum criteria",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=210'
        },
        {
            text:"New Applications in Review",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=220'
        },
        {
            text:"New Applications in Assessment",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=230'
        },
        {
            text:"Active Auditors in Level Review",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=240'
        },
        {
            text:"Active Auditors",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=250'
        },
        {
            text:"Active Auditors with unverified audits",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=260'
        },
        {
            text:"Active Auditors with unconfirmed audits",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=280'
        },
        {
            text:"Active Auditors with no verified courses",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=270'
        }
    ])

    const [Audits, setAudits] = useState([
        {
            text:"List of Verified Audits with unconfirmed Auditors",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=0'
        },
        {
            text:"List of verified Road Safety Audits",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=3'
        },
        {
            text:"List of pending Road Safety Audits to be verified",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=1'
        },
        {
            text:"List of pending Road Safety Audits in review process",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=2'
        },
        {
            text:"List of not recognised Road Safety Audits",
            value: "0",
            onClick: ()=>window.location.href = '/search/auditors?status=5'
        }
    ])

    useEffect(()=>{
    if(props.AuditorCounts){
        const NewValuesForAuditor = [
            {
                text:"New Applications ready for Admin",
                value: props.AuditorCounts.newApplicationsReadyForAdmin.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=200'
            },
            {
                text:"New Applications not meeting minimum criteria",
                value: props.AuditorCounts.newApplicationsWithIncompleteReg.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=210'
            },
            {
                text:"New Applications in Review",
                value: props.AuditorCounts.newApplicationsInReview.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=220'
            },
            {
                text:"New Applications in Assessment",
                value: props.AuditorCounts.newApplicationsinAssessment.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=230'
            },
            {
                text:"Active Auditors in Level Review",
                value: props.AuditorCounts.activeAuditorsInLevelReview.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=240'
            },
            {
                text:"Active Auditors",
                value: props.AuditorCounts.activeAuditors.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=250'
            },
            {
                text:"Active Auditors with unverified audits",
                value: props.AuditorCounts.auditorsWithUnverifiedAudits.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=260'
            },
            {
                text:"Active Auditors with unconfirmed audits",
                value: props.AuditorCounts.activeAuditorsWithUnconfirmedAudits.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=280'
            },
            {
                text:"Active Auditors with no verified courses",
                value: props.AuditorCounts.activeAuditorsWithAllUnverifiedCourses.toString(),
                onClick: ()=>window.location.href = '/search/auditors?status=270'
            }
        ]
        setAuditors(NewValuesForAuditor)
    }

    },[props.AuditorCounts])
    useEffect(()=>{
        if(props.AuditCounts){
            const NewValuesForAudit = [
                {
                    text:"List of Verified Audits with unconfirmed Auditors",
                    value: props.AuditCounts.verifiedAuditswithunconfirmedAuditors.toString(),
                    onClick: ()=>window.location.href = '/search/auditors?status=0'
                },
                {
                    text:"List of verified Road Safety Audits",
                    value: props.AuditCounts.verifiedRoadSafetyAudits.toString(),
                    onClick: ()=>window.location.href = '/search/auditors?status=3'
                },
                {
                    text:"List of pending Road Safety Audits to be verified",
                    value: props.AuditCounts.pendingRoadSafetyAuditstobeverified.toString(),
                    onClick: ()=>window.location.href = '/search/auditors?status=1'
                },
                {
                    text:"List of pending Road Safety Audits in review process",
                    value: props.AuditCounts.pendingRoadSafetyAuditsinreviewprocess.toString(),
                    onClick: ()=>window.location.href = '/search/auditors?status=2'
                },
                {
                    text:"List of not recognised Road Safety Audits",
                    value: props.AuditCounts.notrecognisedRoadSafetyAudits.toString(),
                    onClick: ()=>window.location.href = '/search/auditors?status=5'
                }
            ]
            setAudits(NewValuesForAudit)
        }

    },[props.AuditCounts])

    return (
        <Grid>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <Grid>
                        <Grid.Row columns={2}>
                            <Grid.Column width={3}>
                                <p style={{fontWeight:'bolder', fontSize:'25px'}}>Auditors</p>
                            </Grid.Column>
                            <Grid.Column id={'auditorSearch'} style={{display:'flex', justifyContent:'start', fontSize:'25px',transition:'0.4s'}}>
                                {/*<FontAwesomeIcon icon={solid('magnifying-glass')} onClick={()=>{*/}
                                {/*    //eslint-disable-next-line*/}
                                {/*    let element = document.getElementById('auditorSearch')*/}
                                {/*    if(element) {*/}
                                {/*        if(element.style.justifyContent == 'end') {*/}
                                {/*            element.style.transition = '0.4s'*/}
                                {/*            element.style.justifyContent = 'start'*/}
                                {/*        }else {*/}
                                {/*            element.style.justifyContent = 'end'*/}
                                {/*        }*/}

                                {/*    }*/}

                                {/*}}/>*/}
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>

                </Grid.Column>
                <Grid.Column>
                    <p style={{fontWeight:'bolder', fontSize:'25px'}}>Road Safety Audits</p>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
                <Grid.Column>
                    <NotificationMenu
                        Data={Auditors}
                    />
                </Grid.Column>
                <Grid.Column>
                    <NotificationMenu
                        Data={Audits}
                    />
                </Grid.Column>
            </Grid.Row>
        </Grid>
    );
}

export default AdminNotification





