import React, { useEffect, useState } from 'react'
import { Checkbox, Popup } from 'semantic-ui-react'
import { Pagination } from 'antd';
import AddNewAudit from "../../../pages/auditor/profile/AddNew Audit";
import { getSessionCookie } from "../../common/SessionHandler";
import { fetchAuditStages, fetchStates } from "../../../pages/auditor/profile/ProfileController";
import AddNewAuditView from "./AddNew AuditView";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { faUser } from "@fortawesome/free-solid-svg-icons";

interface Props {
    id?: string,
    head: object[],
    body: object[],
    pageCount?: number,
    tableHeadColored?: boolean | string,
    current?: number,
    onChange?: any,
    center?: boolean,

    // any props that come into the component
}

const RSATable = ({ id, head, body, tableHeadColored, pageCount, current, center, onChange }: Props) => {

    console.log("RsaTableProps: ", body);
    console.log("PageCount: ", pageCount);
    console.log("Current Page: ", current);
    console.log("Pagination On Change: ", onChange);
    const pathName = window.location.pathname;
    const [auditStages, setAuditStages] = useState<any>();
    const [states, setStates] = useState<any>();

    useEffect(() => {
        setStates(fetchStates());
        setAuditStages(fetchAuditStages());
        //console.log("Fetch states called from src/framework/widget/RSATABLE.");
    }, []);

    return (
        <React.Fragment>
            {/*very compact table*/}
            <table id={id} className="ui table background-transaparent ">
                <thead className={tableHeadColored ? "background-colored" : "background-transaparent"}>
                    <tr key={1}>
                        {head.map((r: any, index) => {
                            if (r.text == "icon") {
                                return (
                                    <th key={index} ref={element => {
                                        if (element && (r.text.includes('Date') || r.text.includes('Sign-Off') || r.text.includes('Elapses'))) {
                                            element.style.setProperty('width', '150px', 'important')
                                            //element.style.setProperty('display', 'flex', 'important')
                                        }
                                    }}
                                        className={tableHeadColored ? tableHeadColored == 'blue' ? "background-colored-blue" : "background-colored" : "background-transaparent"}
                                        style={center ? { textAlign: 'center' } : undefined}><FontAwesomeIcon icon={faUser} title={'Auditor involvement'} /></th>
                                )
                            } else {
                                return (
                                    <th key={index} ref={element => {
                                        if (element && (r.text.includes('Date') || r.text.includes('Sign-Off') || r.text.includes('Elapses'))) {
                                            element.style.setProperty('width', '150px', 'important')
                                            //element.style.setProperty('display', 'flex', 'important')
                                        }
                                    }}
                                        className={tableHeadColored ? tableHeadColored == 'blue' ? "background-colored-blue textWrap" : "background-colored textWrap" : "background-transaparent textWrap"}
                                        style={center ? { textAlign: 'center' } : undefined}>{r.text}</th>
                                )
                            }

                        })}
                    </tr>
                </thead>
                <tbody>
                    {body.map((dr: any, index) => (
                        <TableRow
                            key={index}
                            dataRow={dr}
                            index={index}
                            center={center}
                            states={states}
                            auditStages={auditStages}
                        />
                    ))}
                </tbody>
                {pageCount != undefined && pageCount > 1 ?
                    <tfoot className={tableHeadColored ? "background-colored" : "background-transaparent"}>
                        {pathName == '/audits' ? (<tr>
                            <th colSpan={1}
                                className={tableHeadColored ? "background-colored" : "background-transaparent"}>

                            </th>
                            <th colSpan={4}
                                className={tableHeadColored ? "background-colored" : "background-transaparent"}>
                                <Pagination current={current ? current : 1} onChange={onChange} total={pageCount * 10}
                                    pageSize={10} showSizeChanger={false} />
                            </th>
                        </tr>)
                            :
                            (<tr>
                                <th colSpan={head.length > 5 ? head.length - 5 : head.length - 2}
                                    className={tableHeadColored ? "background-colored" : "background-transaparent"}>

                                </th>
                                <th colSpan={head.length > 5 ? head.length - 5 : head.length - 2}
                                    className={tableHeadColored ? "background-colored" : "background-transaparent"}>
                                    <Pagination current={current ? current : 1} onChange={onChange} total={pageCount * 10}
                                        pageSize={10} showSizeChanger={false} />
                                </th>
                            </tr>)}
                    </tfoot> : null}
            </table>
        </React.Fragment>
    )
}
export default RSATable

interface TableRowProps {
    dataRow: object[]
    index: number,
    center?: boolean,
    states: any; // Add the states prop
    auditStages: any; // Add the auditStages prop
}

const TableRow = ({ dataRow, index, center, states, auditStages }: TableRowProps) => {
    //console.log("RSA Table Row",dataRow)   
    const session = getSessionCookie();

    const toggleModel = (e: any) => {
        console.log(e)
    }

    return (
        <tr key={index}>
            {dataRow.map((r: any, index) => {
                if (r.tag === "text") {
                    return (
                        <td key={index} ref={element => {
                            if (element && r.width == '251') {
                                element.style.setProperty('width', `${r.width}px`, 'important')
                                //element.style.setProperty('display', 'flex', 'important')
                            } else if (element && r.width) {
                                element.style.setProperty('width', r.width, 'important')
                                //element.style.setProperty('display', 'inline-block', 'important')
                                //element.style.setProperty('margin-top', '-16px', 'important')
                                element.className = 'element_with_width'
                            }
                        }} style={{ textAlign: center ? 'center' : 'left' }}>{r.text}</td>)
                } else if (r.tag === "link") {
                    return (<td key={index} style={{ width: r.width }}><a href={r.href ? r.href : '#'} onClick={r.onclick} target={'blank'}
                        style={{ width: r.width, color: '#29599e', fontWeight: 'bold' }}>{r.text}</a></td>)
                } else if (r.tag === "button") {
                    return (<td key={index}><a style={{ width: r.width, cursor: 'pointer', color: '#29599e', fontWeight: 'bold', display: 'inline-block' }}
                        onClick={r.onclick}>{r.text}</a></td>)
                } else if (r.tag === "checkbox") {
                    return (
                        <td key={index} style={{
                            textAlign: 'center',
                            color: r.onclick ? '#29599e' : '',
                            cursor: r.onclick ? 'pointer' : 'default',
                            display: 'flex',
                            justifyContent: 'center'
                        }} align={'center'}><Checkbox type='checkbox' onClick={r.onclick} checked={r.text} />
                        </td>)
                } else if (r.tag === "icon") {
                    return (<td key={index} style={{
                        textAlign: center ? 'center' : 'left',
                        color: r.onclick ? '#29599e' : '',
                        cursor: r.onclick ? 'pointer' : 'default'
                    }} align={'center'} onClick={r.onclick}><i className={`icon ${r.icon}`}>{r.text}</i></td>)
                } else if (r.tag === "icon-fa") {
                    let icon: IconProp = 'file-exclamation';
                    switch (r.icon.toString()) {
                        case 'view':
                            icon = regular('eye');
                            break;
                        case 'delete':
                            icon = regular('trash-can');
                            break;
                        case 'check':
                            icon = solid('square-check');
                            break;
                        case 'minus':
                            icon = regular('square');
                            break;
                        case 'file':
                            icon = regular('file');
                            break;
                        case 'attach':
                            icon = solid('paperclip');
                            break;
                        case 'edit':
                            icon = solid('pen');
                            break;
                    }
                    return (<td key={index} style={{
                        textAlign: center || r.text == 'center' ? 'center' : 'left',
                        color: r.onclick ? r.icon.toString() == 'attach' ? '#CD123D' : '#4897D3' : r.icon.toString() == 'attach' ? '#f36988' : '#4897D3',
                        cursor: r.onclick ? 'pointer' : 'default',
                        display: r.text == 'tfnsw' ? 'flex' : 'table-cell',
                        justifyContent: r.text == 'tfnsw' ? 'center' : 'start'
                    }} align={'center'} title={r.text == 'tfnsw' || r.text == 'center' ? r.icon.toString() == 'attach' ? 'No document has been attached' : '' : r.text} onClick={r.onclick} ><FontAwesomeIcon icon={icon} size={"lg"} /></td>)
                } else if (r.tag === "popup") {
                    return (
                        <Popup
                            flowing
                            hoverable
                            position={"top center"}
                            style={{
                                width: '100vw'
                            }}
                            //on='click'
                            //pinned
                            trigger={
                                <td key={index} style={{
                                    textAlign: center ? 'center' : 'left',
                                    fontWeight: 'bold',
                                    color: r.onclick ? '#29599e' : '',
                                    cursor: r.onclick ? 'pointer' : 'default'
                                }} align={'center'} onClick={r.onclick}>{r.text}
                                </td>
                            }
                            key={index}
                        >
                            <Popup.Header>Audit Details</Popup.Header>
                            <Popup.Content style={{ maxHeight: '30vh', overflow: 'auto', width: '5vw' }}>
                                <AddNewAuditView
                                    states={states}
                                    auditStages={auditStages}
                                    auditId={r.onclick}
                                    toggleModel={toggleModel}
                                    isEdit={true}
                                    auditorId={session.auditorId}
                                    setAuditor={null}
                                />
                            </Popup.Content>
                        </Popup>
                    )
                } else if (r.tag === "popup-icon") {
                    return (
                        <Popup
                            flowing
                            hoverable
                            trigger={
                                <td key={index} style={{
                                    textAlign: center ? 'center' : 'left',
                                    fontWeight: 'bold',
                                    color: r.onclick ? '#29599e' : '',
                                    cursor: r.onclick ? 'pointer' : 'default'
                                }} align={'center'} onClick={r.onclick}>
                                    <FontAwesomeIcon icon={regular('eye')} />
                                </td>
                            }
                            key={index}
                        >
                            <Popup.Header>Audit Details</Popup.Header>
                            <Popup.Content style={{ maxHeight: '30vh', overflow: 'auto', maxWidth: '90vw' }}>
                                <AddNewAuditView
                                    states={states}
                                    auditStages={auditStages}
                                    auditId={r.onclick}
                                    toggleModel={toggleModel}
                                    isEdit={true}
                                    auditorId={session.auditorId}
                                    setAuditor={null}
                                />
                            </Popup.Content>
                        </Popup>
                    )
                } else {
                    return null
                }
            })}
        </tr>
    )
}