import React, {useEffect} from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import TitleBar from "../../framework/layouts/TitleBar";
import HomeSlider from "./HomeSlider";
import HomeSliderBottom from "./HomeSliderBottom";
import MiddleLeftTripo from "./MiddleLeftTripo";
import {GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const Home = () => {
    useEffect(()=>{
        sessionStorage.clear();
    },[])
    return (
        <>
            <GoogleReCaptchaProvider
                reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY ? process.env.REACT_APP_RECAPTCHA_KEY : ""}>
                <Header>
                    <HomeSlider/>
                    <MiddleLeftTripo/>
                    <HomeSliderBottom/>
                </Header>
                <Footer/>
            </GoogleReCaptchaProvider>
        </>
    )
}

export default Home