import React, {useEffect} from "react";
import Header from "../../framework/layouts/Header";
import Footer from "../../framework/layouts/Footer";
import TrainingContainer from "./TrainingContainer";


const Training = () => {
    return(
        <React.Fragment>
            <Header>
                <TrainingContainer/>
            </Header>
            <Footer/>
        </React.Fragment>
    );
}

export default Training